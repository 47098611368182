export const ANIMATED_SPRING_FRICTION = 6.75;
export const ANIMATED_SPRING_TENSION = 100;
export const ANIMATED_ELASTIC_DURATION = 300;
export const ANIMATED_ELASTIC_BOUNCINESS = 1.2;
export const ANIMATED_TIMING_OFF = 100;
export const ANIMATED_TIMING_IN = 200;
export const ANIMATED_TIMING_LOADING = 3000;
export const DEFAULT_RAISE_LEVEL = 4;
export const DEFAULT_ACTIVE_OPACITY = 1;
export const DEFAULT_RELEASE_DELAY = 100;
export const DEFAULT_HEIGHT = 60;
export const DEFAULT_WIDTH = null;
export const DEFAULT_BORDER_RADIUS = 4;
export const DEFAULT_BORDER_WIDTH = 0;
export const DEFAULT_HORIZONTAL_PADDING = 12;
export const DEFAULT_TEXT_COLOR = "#FFFFFF";
export const DEFAULT_ACTIVITY_COLOR = "#FFFFFF";
export const DEFAULT_BACKGROUND_COLOR = "#c0c0c0";
export const DEFAULT_BACKGROUND_DARKER = "#9f9f9f";
export const DEFAULT_BACKGROUND_SHADOW = "rgba(0, 0, 0, 0.15)";
export const DEFAULT_BACKGROUND_ACTIVE = "rgba(0, 0, 0, 0.15)";
export const DEFAULT_LINE_HEIGHT = 20;
export const DEFAULT_TEXT_SIZE = 14;

export const TWITTER = {
    backgroundColor: "#00aced",
    backgroundDarker: "#0096cf"
  };
  
  export const MESSENGER = {
    backgroundColor: "#3186f6",
    backgroundDarker: "#2566bc"
  };
  
  export const FACEBOOK = {
    backgroundColor: "#4868ad",
    backgroundDarker: "#325194"
  };
  
  export const GITHUB = {
    backgroundColor: "#2c3036",
    backgroundDarker: "#060708"
  };
  
  export const LINKEDIN = {
    backgroundColor: "#0077b5",
    backgroundDarker: "#005885"
  };
  
  export const WHATSAPP = {
    backgroundColor: "#25d366",
    backgroundDarker: "#14a54b"
  };
  
  export const REDDIT = {
    backgroundColor: "#fc461e",
    backgroundDarker: "#d52802"
  };
  
  export const PINTEREST = {
    backgroundColor: "#bd091c",
    backgroundDarker: "#980313"
  };
  
  export const YOUTUBE = {
    backgroundColor: "#cc181e",
    backgroundDarker: "#ab0d12"
  };