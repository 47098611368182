import { StatusBar } from 'expo-status-bar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import Toast from 'react-native-toast-message';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

const queryClient = new QueryClient()

const auth = getAuth();
// Listen for authentication state to change.
// onAuthStateChanged(auth, user => {
//   if (user != null) {
//     user.getIdToken().then((token) => {      
//     })
//   }
// });

// auth.signOut().then();

const removeToken = async () => {
  try {
    await AsyncStorage.removeItem('userToken');
    return true;
  } catch (e) {
    return false;
  }
}

// removeToken().then(res => console.log('removed token'));

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <>
        <QueryClientProvider client={queryClient}>
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme} />
            <StatusBar />
          </SafeAreaProvider>
        </QueryClientProvider>
        <Toast />
      </>

    );
  }
}
