import React, { useState } from 'react';
import { FlatList, Appearance, Pressable, StyleSheet, Button, TouchableOpacity, useColorScheme } from 'react-native';
import moment from 'moment';
import { View, Text } from './Themed'
import Separator from './Separator';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../constants/Colors'
// import * as Animatable from 'react-native-animatable';

const WeekCard = (props) => {
    const entry = props.entry;
    const colorScheme = useColorScheme();
    return (
        <>
            <View style={{
                flex: 0.25,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: colorScheme == 'dark' ? '#828c89' : '#243665'
            }}>
                <Text style={{
                    fontSize: 17,
                    color: 'white',
                    textTransform: 'uppercase',
                }}>{entry.year}</Text>
            </View>
            <View style={{
                alignItems: 'center',
                flex: 0.7,
                justifyContent: 'center',
            }}>
                <Text style={{ fontSize: 12, }}>
                    {moment(entry.week_dates[0]).format("MMM. Do")} - {moment(entry.week_dates[6]).format("MMM. Do")}
                </Text>
            </View>
        </>
    )
}

const ListItem = (props) => {
    const weightEntry = props.singleEntry;
    const navigation = props.navigation;

    return (
        <TouchableOpacity onPress={() => navigation.navigate('Modal', { weightEntry })}
        // onLongPress={() => Alert.alert('Delete Weight Entry', "", [{text: 'Delete',  style: 'destructive', onPress: () => patchWeightEntryMutation.mutate()}, {text: 'Cancel', style: 'cancel'}])}
        >
            <View style={{ flexDirection: 'row', padding: 5, borderRadius: 1, borderColor: 'gray', borderWidth: 2 }}>
                <View style={{ flex: 1 }}>
                    <Text>
                        {moment(weightEntry.date).format("MMM. Do")}
                    </Text>
                </View>
                <View style={{ flex: 1 }}>
                    {weightEntry.weight == null ? (<Text style={{textAlign: 'center'}}>-</Text>) : (<Text>{weightEntry.weight} lbs</Text>)}
                </View>
                <View style={{ flex: 1 }}>

                    {weightEntry.calories == null ? (<Text style={{textAlign: 'center'}}>-</Text>) : (<Text>{weightEntry.calories} calories</Text>)}
                </View>
            </View>
        </TouchableOpacity>
    )
}

const WeeklyCard = (props) => {
    const entry = props.entry;
    const tdee = entry.avg_calories ? (entry.avg_calories - (entry.difference * 500)).toFixed(0) : "N/A"
    const [showList, setShowList] = useState(false);
    const colorScheme = useColorScheme();
    return (
        <>
            <View style={{
                flexDirection: 'row',
                borderRadius: 10,
                shadowColor: "#000000",
                shadowOffset: { width: 0, height: 4 },
                shadowOpacity: 0.3,
                shadowRadius: 10,
                padding: 20,
            }}>
                <View style={{
                    flex: 0.25,
                    shadowColor: "#000000",
                    shadowOffset: { width: 0, height: 4 },
                    shadowOpacity: 0.3,
                    shadowRadius: 10,
                    flexDirection: 'column',
                    marginRight: 20,
                    overflow: "hidden",
                    height: 90,
                    borderColor: colorScheme == 'dark' ? 'white' : null,
                    borderWidth: 1,
                    borderRadius: 10
                }}
                >
                    <WeekCard entry={props.entry} />
                </View>
                <View style={{
                    flex: 0.55,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <View style={{flexDirection: 'row'}}>
                    <Text style={{ fontSize: 22 }}>
                        {entry.avg_weight?.toFixed(1)} lbs 
                    </Text>
                    <Text style={{ 
                        fontSize: 22, 
                        paddingLeft: 7, 
                        color: entry.difference < 0 ? '#F55656' : '#15B371'}}
                    >
                    ({(entry.difference <= 0 ? "" : "+") + entry.difference?.toFixed(2)})
                    </Text>
                    </View>
                    <Text style={styles.caption}>Avg. calories: {entry.avg_calories?.toFixed(0) || "N/A"}</Text>
                    <Text style={styles.caption}>TDEE: {tdee}</Text>
                </View>
                <View style={{
                    flex: 0.2,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Pressable
                        onPress={() => setShowList(!showList)}
                        hitSlop={50}
                        style={({ pressed }) => ({
                            opacity: pressed ? 0.5 : 1,
                        })}>
                        <FontAwesome
                            name={showList ? "chevron-up" : "chevron-down"}
                            size={25}
                            color={Colors[colorScheme].text}
                            style={{ marginLeft: 15 }}
                        />
                        
                    </Pressable>
                </View>
            </View>
            {showList ?
                // (<Animatable.View animation={showList ? "fadeIn" : "fadeOut"}>
                <FlatList
                    style={{ padding: 20, paddingTop: 10 }}
                    data={entry.objects}
                    keyExtractor={(item, index) => item.date}
                    ListHeaderComponentStyle={{ justifyContent: 'center', alignItems: 'center', paddingBottom: 3 }}
                    ListHeaderComponent={<Text style={{ justifyContent: 'center' }}>(Tap to Edit)</Text>}
                    renderItem={({ item }) => (<ListItem singleEntry={item} navigation={props.navigation} queryClient={props.queryClient} animation={showList ? "fadeIn" : "fadeOut"} />)} />
                // </Animatable.View>) 
                : null}
            {colorScheme == 'dark' ? <Separator /> : <View style={{
                marginVertical: 1,
                height: 1,
                width: '100%',
                opacity: 0,
            }} />}
        </>
    )
}

const styles = StyleSheet.create({
    caption: {
        fontWeight: "500",
        fontSize: 12
    }
})

export default WeeklyCard