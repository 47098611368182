import * as React from 'react';
import { StyleSheet, ActivityIndicator, useColorScheme, Pressable } from 'react-native';

import { View, Text, ScrollView } from '../components/Themed';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API } from '../api';
import { TokenContext } from '../navigation';
import StyledTextInput from '../components/StyledTextInput';
import AwesomeButtonRick from '../components/rick';
import moment from 'moment';
import Toast from 'react-native-toast-message';


export default function CreateBlockScreen({ navigation }) {
    const globalState = React.useContext(TokenContext);
    const colorScheme = useColorScheme();
    const [goalTitle, setGoalTitle] = React.useState("");
    const [goal, setGoal] = React.useState("Maintain"); // Maintain, Cut, Bulk
    const [targetChange, setTargetChange] = React.useState("0");
    const queryClient = useQueryClient();
    const account = useQuery(API.accountInfo.query_id, API.accountInfo.query);
    const { isLoading, isError, data, error } = useQuery(API.latestActiveWeightGoal.query_id, API.latestActiveWeightGoal.query);
    if (isLoading) {
        return <View style={styles.container}><ActivityIndicator size="large" /></View>
    }

    if (isError) {
        // @ts-ignore
        return <View style={styles.container}><Text style={styles.title}>Erro   r: {error.message}</Text></View>
    }
    

    const createGoalMutation = useMutation(
        () => {
            return API.createWeightGoal.query(
                account.data.userid,
                targetChange,
                moment().format("YYYY-MM-DD"),
                goalTitle == "" ? moment().format("YYYY-MM-DD") : goalTitle
            )
        },
        {
            onSuccess: (data) => {
                Toast.show({ type: 'success', text1: "New Goal Created! You got this!" })
                navigation.navigate('BlockScreen', data);
                queryClient.refetchQueries();
            },
            onError: (error, variables, context) => {
                Toast.show({ type: 'error', text1: "Couldn't create new goal! :(" })
                console.log("createGoalMutation error", error);
            },
        }
    );

    return (
        <ScrollView style={styles.container}>
            <View style={styles.separator} />
            <View style={{ alignItems: 'center' }}>
                <Text>Title</Text>
                <StyledTextInput
                    onChangeText={(value) => setGoalTitle(value)}
                    // keyboardType=""
                    returnKeyType="done"
                    autoCapitalize="none"
                    value={goalTitle}
                    placeholder='ex. "Winter Bulk"'
                />
                <View style={{ flexDirection: 'row', padding: 20 }}>
                    <Pressable onPress={() => { setGoal("Bulk"); setTargetChange("0.75") }} style={{ borderWidth: 1, borderColor: 'blue', borderStyle: 'solid', alignContent: 'center', padding: 10, backgroundColor: goal == "Bulk" ? 'gray' : null }}>
                        <Text>Bulk</Text>
                    </Pressable>
                    <Pressable onPress={() => { setGoal("Maintain"); setTargetChange("0") }} style={{ borderWidth: 1, borderColor: 'blue', borderStyle: 'solid', alignItems: 'center', padding: 10, backgroundColor: goal == "Maintain" ? 'gray' : null }}>
                        <Text>Maintain</Text>
                    </Pressable>
                    <Pressable onPress={() => { setGoal("Cut"); setTargetChange("-1") }} style={{ borderWidth: 1, borderColor: 'blue', borderStyle: 'solid', alignContent: 'center', padding: 10, backgroundColor: goal == "Cut" ? 'gray' : null }}>
                        <Text>Cut</Text>
                    </Pressable>
                </View>
                <Text>Target Weight Change Per Week (in lbs):</Text>
                <StyledTextInput
                    onChangeText={(value) => {
                        setTargetChange(value);
                        if (Number(value) == 0) {
                            setGoal("Maintain");
                        } else if (Number(value) < 0) {
                            setGoal("Cut");
                        } else {
                            setGoal("Bulk");
                        }
                    }}
                    keyboardType="numeric"
                    returnKeyType="done"
                    autoCapitalize="none"
                    value={targetChange}
                    placeholder="Target (lbs)"
                />
                <Text style={{ padding: 25, paddingBottom: 15, textAlign: "center" }}>
                    To <Text style={{ fontWeight: 'bold' }}>{Number(targetChange) > 0 ? "gain" : "lose"} {targetChange} lbs/week</Text>, you will need to eat around <Text style={{ fontWeight: 'bold' }}>{Number(account.data.tdee) + (500 * targetChange)} calories per day</Text>, based on a current TDEE of {account.data.tdee}.
                </Text>
                <AwesomeButtonRick
                    backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
                    backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
                    width={200}
                    style={{ marginBottom: 15 }}
                    onPress={() => {
                        if (JSON.stringify(data) != '{}') {
                            Toast.show({ type: 'error', text1: "You already have an active goal!" })
                        } else {
                            createGoalMutation.mutate();
                        }
                    }}
                >
                    <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>Create Goal</Text>
                </AwesomeButtonRick>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 30,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginLeft: 15,
    },
    separator: {
        marginTop: 15,
        height: 1,
        width: '80%',
    },
});
