import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { Appearance, Platform, StyleSheet, TextInput, useColorScheme, TouchableOpacity, Button, Dimensions } from 'react-native';

import { Text, View } from '../components/Themed';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as theme from '../theme';
import moment from 'moment';
import DateTimePicker from 'react-native-modal-datetime-picker';
import { API } from '../api';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../constants/Colors';
import StyledTextInput from '../components/StyledTextInput';
import AwesomeButtonRick from '../components/rick';

const getOrNull = (value) => { return value == '' ? null : value };
const wait = (timeout) => {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

export default function MeasurementModalScreen({ navigation: { goBack }, route }) {
  const measurement_type = route.params?.measurement_type;
  const measurementObject = route.params?.measurement;
  const [measurement, setMeasurement] = React.useState(measurementObject ? route.params?.measurement.measurement : '');

  const [isDatePickerVisible, setDatePickerVisibility] = React.useState(false);
  const [date, setDate] = React.useState(measurementObject?.date ? new Date(`${measurementObject?.date}T12:00:00`) : new Date());
  const colorScheme = useColorScheme();
  const queryClient = useQueryClient();

  const showDatePicker = () => {
    if (!measurementObject) {
      setDatePickerVisibility(true);
    }
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleDatePicked = (date) => {
    setDate(date);
    hideDatePicker();
  };

  const addMeasurementMutation = useMutation(() => {
    return API.addMeasurement.query(moment(date).format('YYYY-MM-DD'), getOrNull(measurement), measurement_type.id);
    }, {
    onSuccess: () => { queryClient.refetchQueries([API.getMeasurementType.query_id]); goBack() },
    onError: (error, variables, context) => {
      console.log('addMeasurementMutation error', error);
    },
  });

  const patchMeasurementMutation = useMutation(() => {
    return API.patchMeasurement.query(measurementObject.id, measurement);
    }, {
    onSuccess: () => { queryClient.refetchQueries([API.getMeasurementType.query_id]); goBack() },
    onError: (error, variables, context) => {
      console.log('addMeasurementMutation error', error);
    },
  });

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{measurement_type?.title} Measurement</Text>
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <TouchableOpacity style={{ padding: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }} onPress={showDatePicker}>
        <Text style={{ paddingRight: 5, fontSize: 20 }}>{moment(date).format("dddd, MMMM Do, YYYY")}</Text>
        {!measurementObject && <FontAwesome
          name="edit"
          size={18}
          color={Colors[colorScheme].text}
          style={{ margin: 5 }}
        />}
      </TouchableOpacity>
      <DateTimePicker
        isVisible={isDatePickerVisible}
        onConfirm={handleDatePicked}
        onCancel={hideDatePicker}
        isDarkModeEnabled={colorScheme == 'dark'}
        date={date}
        maximumDate={new Date()}
      />
      <StyledTextInput
        onChangeText={(value) => setMeasurement(value)}
        keyboardType='numeric'
        returnKeyType='done'
        autoCapitalize="none"
        value={measurement}
        placeholder="Measurement (inches)"
      />
      <View style={{ padding: 10, flexDirection: 'row' }}>
        <View style={{ flex: 1, alignItems: 'center' }}>
          {/* @ts-ignore */}
        <AwesomeButtonRick 
              progress
              backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
              backgroundDarker={colorScheme == 'dark' ? "#202B33": "#1F4B99"}
              width={Dimensions.get('screen').width - 75}
              disabled={!measurement}
              onPress={() => { if (measurementObject) { patchMeasurementMutation.mutate() } else { addMeasurementMutation.mutate() } }}
            >
                <Text style={{fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33'}}>{measurementObject ? "Update" : "Submit"}</Text>
            </AwesomeButtonRick>
        </View>
      </View>
      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingTop: '10%'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    width: '80%',
    borderRadius: 10,
  },
});