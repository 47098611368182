/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Pressable } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import ModalScreen from '../screens/ModalScreen';
import HomeScreen from '../screens/HomeScreen';
import WeightListScreen from '../screens/WeightListScreen';
import BlockListScreen from '../screens/BlockListScreen';
import BlockScreen from '../screens/BlockScreen';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import { useQueryClient } from 'react-query';
import MeasurementListScreen from '../screens/MeasurementListScreen';
import MeasurementScreen from '../screens/MeasurementScreen';
import MeasurementModalScreen from '../screens/MeasurementModalScreen';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import LoginScreen from '../screens/LoginScreen';
import { getAuth } from 'firebase/auth';
import { API } from '../api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import SettingsScreen from '../screens/SettingsScreen';
import CreateBlockScreen from '../screens/CreateBlockScreen';

export const AuthContext = React.createContext(undefined);
export const TokenContext = React.createContext(undefined);

const auth = getAuth();
// Listen for authentication state to change.
// onAuthStateChanged(auth, user => {
//   if (user != null) {
//     user.getIdToken().then((token) => {
//       console.log('jwt token', token);
//     })
//   }
// });

const getToken = async () => {
  try {
    const value = await AsyncStorage.getItem('userToken')
    if (value !== null) {
      return value;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

const setToken = async (value) => {
  try {
    await AsyncStorage.setItem('userToken', value);
    return true;
  } catch (e) {
    return false;
  }
}


const removeToken = async () => {
  try {
    await AsyncStorage.removeItem('userToken');
  } catch (e) {
  }
}


export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          axios.defaults.headers.common['Authorization'] = 'Token ' + action.token;
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          };
        case 'SIGN_IN':
          axios.defaults.headers.common['Authorization'] = 'Token ' + action.token;
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case 'SIGN_OUT':
          axios.defaults.headers.common['Authorization'] = null;
          removeToken().then();
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  const tokenContext = state;

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken;

      try {
        // userToken = 'fd15d86272a881f9778a6d0435f72cb74b61230f';
        userToken = await AsyncStorage.getItem('userToken');
        console.log('user token gotten:', userToken);
      } catch (e) {
        // Restoring token failed
        console.log('no token found!');
      }
      // After restoring token, we may need to validate it in production apps
      if (userToken != null) {
        console.log('hit this');
        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
        API.validate.query(userToken).then((res : {valid}) => {
          console.log('got res:', res?.valid);
          if (res?.valid) {
            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            // console.log('dispatching userToken: ', userToken);
          }
        })
      }
    };

    bootstrapAsync();
  }, []);

  const authContext = React.useMemo(
    () => ({
      signIn: async (providedUser=null) => {
        if (providedUser != null) {
          console.log('accessToken', providedUser.accessToken);
          API.getAuthToken.query(providedUser.accessToken).then(token => {
            console.log('got token from auth/getToken', token)
            setToken(token).then(res => {
              console.log('set token', token);
              console.log('dispatching and setting status:', res);
            });

            dispatch({ type: 'SIGN_IN', token: token });
          }).catch(error => {
            console.log('error signing in', error);
            console.log('error signing in data', error.data);
          })
        }
        
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        if (providedUser == null && auth.currentUser == null) {
          auth.onAuthStateChanged(user => {
            if (user != null) {
              user.getIdToken(true).then(jwtToken => {
                API.getAuthToken.query(jwtToken).then(token => {
                  setToken(token).then(res => {
                    console.log('set token', token);
                    console.log('dispatching and setting status:', res);
                  });
      
                  dispatch({ type: 'SIGN_IN', token: token });
                }).catch(error => {
                  console.log('error', error);
                })
              })
            }
          })
        } else {
          auth.currentUser.getIdToken(true).then(jwtToken => {
            API.getAuthToken.query(jwtToken).then(token => {
              setToken(token).then(res => {
                console.log('set token', token);
                console.log('dispatching and setting status:', res);
              });
  
              dispatch({ type: 'SIGN_IN', token: token });
            }).catch(error => {
              console.log('error', error);
            })
          })
        }
        

      },
      signOut: () => dispatch({ type: 'SIGN_OUT' }),
    }),
    []
  );

  return (
    <AuthContext.Provider value={authContext}>
      <TokenContext.Provider value={tokenContext}>
        <NavigationContainer
          linking={LinkingConfiguration}
          theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
          {state.userToken == null ?  <AuthNavigator /> : <RootNavigator />}
          {/* <RootNavigator />} */}
        </NavigationContainer>
      </TokenContext.Provider>
    </AuthContext.Provider>
  );
}

const AuthStack = createNativeStackNavigator();
function AuthNavigator(props) {
  return (
    <AuthStack.Navigator>
      <AuthStack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{
          title: 'Login',
        }}
      />
    </AuthStack.Navigator>
  )
}

const BlockStack = createNativeStackNavigator();

function BlockNavigator() {
  return (
    <BlockStack.Navigator>
      <BlockStack.Screen
        name="BlockListScreen"
        component={BlockListScreen}
        options={{
          title: 'Goals',
        }}
      />
      <BlockStack.Screen
        name="BlockScreen"
        component={BlockScreen}
      />
      <BlockStack.Screen
        name="CreateBlockScreen"
        component={CreateBlockScreen}
        options={{
          title: 'Create New Goal',
        }}
      />
    </BlockStack.Navigator>
  )
}

const MeasurementStack = createNativeStackNavigator();

function MeasurementNavigator() {
  return (
    <MeasurementStack.Navigator>
      <MeasurementStack.Screen
        name="MeasurementListScreen"
        component={MeasurementListScreen}
        options={{
          title: 'Measurements',
        }}
      />
      <MeasurementStack.Screen
        name="MeasurementScreen"
        component={MeasurementScreen}
      />
      <MeasurementStack.Group screenOptions={{ presentation: 'modal' }}>
        <MeasurementStack.Screen name="MeasurementModalScreen" component={MeasurementModalScreen} options={{ headerShown: false }} />
      </MeasurementStack.Group>
    </MeasurementStack.Navigator>
  )
}

const SettingsStack = createNativeStackNavigator();

function SettingsNavigator() {
  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          title: 'Settings',
        }}
      />
    </SettingsStack.Navigator>
  )
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const colorScheme = useColorScheme();
  const queryClient = useQueryClient();
  const {signOut} = React.useContext(AuthContext);
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
      {/* <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} /> */}
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} options={{ headerShown: false }} />

      </Stack.Group>
      <BlockStack.Screen
        name="BlockScreen"
        component={BlockScreen}
      />
      <SettingsStack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          title: 'Settings',
        }}
      />
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const queryClient = useQueryClient();
  const {signOut} = React.useContext(AuthContext);


  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}>
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }: RootTabScreenProps<'Home'>) => ({
          title: 'Home',
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
          headerLeft: () => (
            <Pressable 
              onPress={() => navigation.navigate('Settings')}
              // onPress={() => {auth.signOut(); signOut()}}
              // onPress={() => queryClient.refetchQueries()}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="gear"
                // name="refresh"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginLeft: 15 }}
              />
            </Pressable>
          ),
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="plus"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginRight: 15 }}
              />
            </Pressable>
          ),
        })}
      />
      <BottomTab.Screen
        name="WeightListScreen"
        component={WeightListScreen}
        options={({ navigation }) => ({
          title: 'Weight List',
          tabBarIcon: ({ color }) => <TabBarIcon name="list" color={color} />,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="plus"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginRight: 15 }}
              />
            </Pressable>
          ),
        })}
      />
      <BottomTab.Screen
        name="BlockListStack"
        component={BlockNavigator}
        options={{
          title: 'Goals',
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="key" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="MeasurementStack"
        component={MeasurementNavigator}
        options={{
          title: 'Measurements',
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="calculator" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
