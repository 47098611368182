import { View } from './Themed';
import React from 'react';

const Separator = (props) => {
  return (
    <View style={{
      marginVertical: 25,
      height: 1,
      width: '100%',
      ...props.style
    }} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
  )
}

export default Separator