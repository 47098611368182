import React from 'react';
import { Dimensions, Appearance, TouchableOpacity, useColorScheme } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import moment from 'moment';
import { View, Text } from './Themed'

function WeightChart(props) {
    let { data } = props;
    const [trendline, setTrendline] = React.useState(props.trendline ? true : false);
    const colorScheme = useColorScheme()
    if (data == null || data.length < 2) {
        return <View style={{ paddingHorizontal: 5, paddingBottom: 125}}>
            <LineChart
                data={{
                    labels: [1,2,3,4,5],
                    datasets: [{
                        data: [3, 5, 7, 4, 6, 8],
                    }]
                }}
                width={Dimensions.get('window').width - 10} // from react-native
                height={220}
                chartConfig={{
                    backgroundColor: '#2a2a2b',
                    backgroundGradientTo: colorScheme == 'dark' ? '#2a2a2b': '#8BD8BD',
                    backgroundGradientFrom: colorScheme == 'dark' ?'#2a2a2b' : '#243665',
                    decimalPlaces: 1, // optional, defaults to 2dp
                    color: () => 'white',
                    style: {
                        borderRadius: 16,
                    }
                }}
                withDots={false}
                bezier
                withInnerLines={false}
                withOuterLines={false}
                style={{
                    marginVertical: 8,
                    borderRadius: 16,
                    opacity: 0.25
                }}
            />
    <Text style={{textAlign: 'center', marginTop: -125, fontWeight: 'bold', alignItems: 'center'   }}>Add at least 2 entries to display an accurate chart.</Text>
    </View>
    } else {
        if (data.length > 1) {
            if (moment(data[0].date).isAfter(data[1].date)) {
                data = data.reverse();
            }
        }
        data = data.filter(obj => obj?.weight != null);
        let labels = [];
        let _data = [];
        let div = Math.round(data.length / 4);
        for (var i = 0; i < data.length; i++) {
            if (data[i].weight != null) {
                _data.push(parseFloat(data[i].weight));
            }
            if (i % div == 0) {
                labels.push(moment(data[i].date).format("MMM Do").toString());
            }

        }
        const average = (array) => {
            let total = 0;
            for (var i = 0; i < array.length; i++) {
                total += array[i];
            }
            return total/array.length;
        };
        const returnData = {
            labels,
            datasets: [{
                data: _data
            }]
        }
        if (trendline) {
            const add = (average(_data.slice(Math.max(_data.length-5, 0), _data.length-1)) - _data[0]) / _data.length
            let _trend = [_data[0]];
            let prev = _data[0];
            for (let i = 1; i < _data.length; i++) {
                _trend.push(prev + add);
                prev = prev + add;
            }
            returnData.datasets.push({
                data: _trend,
                color: (opacity = 1) => `rgba(255,127,80, 0.8)`,

            });
        }
        
        
        return (
            <TouchableOpacity style={{ paddingHorizontal: 5 }} onPress={() => setTrendline(!trendline)}>
                <LineChart
                    data={returnData}
                    width={Dimensions.get('window').width - 10} // from react-native
                    height={220}
                    chartConfig={{
                        backgroundColor: '#2a2a2b',
                        backgroundGradientTo: colorScheme == 'dark' ? '#2a2a2b': '#8BD8BD',
                        backgroundGradientFrom: colorScheme == 'dark' ?'#2a2a2b' : '#243665',
                        decimalPlaces: 1, // optional, defaults to 2dp
                        color: () => 'white',
                        style: {
                            borderRadius: 16
                        }
                    }}
                    withDots={false}
                    bezier
                    withInnerLines={false}
                    withOuterLines={false}
                    style={{
                        marginVertical: 8,
                        borderRadius: 16
                    }}
                />
            </TouchableOpacity>
        )
    }
}


export default WeightChart