import * as React from 'react';
import { StyleSheet, FlatList, TouchableOpacity, ActivityIndicator, useColorScheme } from 'react-native';

import { View, Text } from '../components/Themed';

import { useQuery } from 'react-query';

import { API } from '../api';
import { blocks } from '../weightgoals_example';
import { TokenContext } from '../navigation';
import Separator from '../components/Separator';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../constants/Colors';


export default function BlockListScreen({ navigation }) {
    const globalState = React.useContext(TokenContext);
    const colorScheme = useColorScheme();
    const token = globalState.userToken;
    const { isLoading, isError, data, error } = useQuery(API.weightGoalList.query_id, API.weightGoalList.query);
    if (isLoading) {
        return <View style={styles.container}><ActivityIndicator size="large" /></View>
    }

    if (isError) {
        // @ts-ignore
        return <View style={styles.container}><Text style={styles.title}>Error: {error.message}</Text></View>
    }

    return (
        <View style={styles.container}>
            <TouchableOpacity
                activeOpacity={0.5}
                style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                onPress={() => navigation.navigate('CreateBlockScreen')}
            >
                <Text style={styles.title}>Create New Goal</Text>
                <FontAwesome
                    name="plus"
                    size={20}
                    color={Colors[colorScheme].text}
                    style={{ marginRight: 15 }} />
            </TouchableOpacity>
            <Separator />
            <FlatList
                data={data as any[]}
                // @ts-ignore
                renderItem={({ item }) => (
                    <>
                        <TouchableOpacity
                            activeOpacity={0.5}
                            key={`block-${item.title}`}
                            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                            onPress={() => navigation.navigate('BlockScreen', item)}
                        >
                            <Text style={styles.title}>{item.title}</Text>
                            <FontAwesome
                                name="chevron-right"
                                size={20}
                                color={Colors[colorScheme].text}
                                style={{ marginRight: 15 }} />
                        </TouchableOpacity>
                        <Separator />
                    </>
                )}
                keyExtractor={(item) => `block-${item.title}`} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 30,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginLeft: 15,
    },
});
