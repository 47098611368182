import { StatusBar } from "expo-status-bar";
import * as React from "react";
import {
  Platform,
  StyleSheet,
  TextInput,
  useColorScheme,
  TouchableOpacity,
  Button,
  Dimensions,
  Switch,
  Alert,
} from "react-native";

import { Text, View } from "../components/Themed";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as theme from "../theme";
import moment from "moment";
import DateTimePicker from "react-native-modal-datetime-picker";
import { API } from "../api";
import { FontAwesome } from "@expo/vector-icons";
import Colors from "../constants/Colors";
import { TokenContext } from "../navigation";
import AwesomeButtonRick from "../components/rick";
import StyledTextInput from "../components/StyledTextInput";
import Separator from "../components/Separator";
import Toast from "react-native-toast-message";

const getOrNull = (value) => {
  return value == "" ? null : value;
};
const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};
export default function ModalScreen({ navigation: { goBack }, route }) {
  const globalState = React.useContext(TokenContext);
  const account = useQuery(API.accountInfo.query_id, API.accountInfo.query);
  const weightList = useQuery(API.weightList.query_id, API.weightList.query);
  const todayExists = (weightList.data as any[])?.find((weight) =>
    moment(weight.date).isSame(moment(), "day")
  );
  const [weightEntry, setWeightEntry] = React.useState(
    null || route.params?.weightEntry || todayExists
  );
  const [weightNumber, onChangeWeightNumber] = React.useState(
    String(weightEntry?.weight) == "null" ||
      String(weightEntry?.weight) == "undefined"
      ? ""
      : String(weightEntry?.weight)
  );
  const [caloriesNumber, onChangeCaloriesNumber] = React.useState(
    String(weightEntry?.calories) == "null" ||
      String(weightEntry?.calories) == "undefined"
      ? ""
      : String(weightEntry?.calories)
  );
  const [isDatePickerVisible, setDatePickerVisibility] = React.useState(false);
  const [date, setDate] = React.useState(
    weightEntry?.date ? new Date(`${weightEntry.date}T12:00:00`) : new Date()
  );
  const [isSwitchEnabled, setIsSwitchEnabled] = React.useState(false);
  const toggleSwitch = () =>
    setIsSwitchEnabled((previousState) => !previousState);
  const colorScheme = useColorScheme();

  const addWeightMutation = useMutation(
    () => {
      Toast.show({ type: "error", text1: "Adding entry..." });
      // return () => console.log('lol');
      return API.addWeightEntry.query(
        moment(date).format("YYYY-MM-DD"),
        getOrNull(weightNumber),
        getOrNull(caloriesNumber)
      );
    },
    {
      // onMutate: async () => {
      //   const previousState = queryClient.getQueryData([API.weightData.query_id, 26]);
      //   let newState = previousState.map(WeekData => 
      //     {
      //       let i = WeekData.week_dates.findIndex((o) => o == moment(date).format("YYYY-MM-DD"));
      //       if (i != -1) {
      //         WeekData.week_dates[i].weight = getOrNull(weightNumber);
      //         WeekData.week_dates[i].calories = getOrNull(caloriesNumber);
      //       }
      //       return {...WeekData}
      //     });
      //   queryClient.setQueryData([API.weightData.query_id, 26], newState);
      //   return { previousState };
      // },
      onSuccess: () => {
        Toast.show({ type: "success", text1: "Added weight entry!" });
        queryClient.refetchQueries();
      },
      onError: (error, variables, context) => {
        Toast.show({ type: "error", text1: "Couldn't add entry! :(" });
        console.log("addWeightMutation error", error);
        // queryClient.setQueryData([API.weightData.query_id, 26], context.previousState)
      },
    }
  );

  const patchWeightEntryMutation = useMutation(
    () => {
      Toast.show({ type: "error", text1: "Editing entry..." });
      return API.patchWeightEntry.query(
        weightEntry.id,
        getOrNull(weightNumber),
        getOrNull(caloriesNumber)
      );
    },
    {
      onSuccess: (data) => {
        Toast.show({ type: "error", text1: "Entry edited!" });
        queryClient.refetchQueries();
      },
      onError: (error, variables, context) => {
        console.log("patchWeightEntryMutation error", error);
      },
    }
  );

  const deleteWeightEntryMutation = useMutation(
    () => {
      Toast.show({ type: "error", text1: "Deleting entry..." });
      return API.deleteWeightEntry.query(weightEntry.id);
    },
    {
      onSuccess: (data) => {
        Toast.show({ type: "success", text1: "Deleted entry!" });
        queryClient.refetchQueries();
        // queryClient.refetchQueries();
      },
      onError: (error, variables, context) => {
        console.log("patchWeightEntryMutation error", error);
      },
    }
  );

  const syncMFPDayMutation = useMutation(
    () => {
      Toast.show({ type: "error", text1: "Pulling from MyFitnessPal..." });
      return API.syncMFPDay.query(
        moment(date).format("YYYY-MM-DD"),
        isSwitchEnabled
      );
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries();
        Toast.show({ type: "success", text1: "Synced day with MyFitnessPal!" });
        goBack();
      },
      onError: (error, variables, context) => {
        Toast.show({
          type: "error",
          text1: "Couldn't sync with MyFitnessPal. :(",
        });
        console.log("patchWeightEntryMutation error", error);
      },
    }
  );

  const queryClient = useQueryClient();

  const checkInvalid = (date) => {
    const momentDate = moment(date);
    if (weightList.data) {
      const found = (weightList.data as any[]).find((weight) =>
        moment(weight.date).isSame(momentDate, "day")
      );
      if (found != undefined) {
        // Weight Entry exists
        setWeightEntry(found);
        onChangeWeightNumber(found.weight || "");
        onChangeCaloriesNumber(
          String(found.calories) == "null" ? "" : String(found.calories)
        );
        return true;
      } else {
        setWeightEntry(null);
        onChangeWeightNumber("");
        onChangeCaloriesNumber("");
        return false;
      }
    }
    return false;
  };

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleDatePicked = (date) => {
    setDate(date);
    checkInvalid(date);
    hideDatePicker();
  };

  return (
    <View style={styles.container}>
      {/* <Text>{JSON.stringify(weightEntry)}</Text> */}
      <Text style={styles.title}>
        {weightEntry && weightEntry?.id ? "Edit" : "Add"} Weight Entry
      </Text>
      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />
      <TouchableOpacity
        style={{
          padding: 10,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={showDatePicker}
      >
        <Text
          style={{
            paddingRight: 5,
            fontSize: 21,
            color: colorScheme == "dark" ? "white" : "black",
          }}
        >
          {moment(date).format("dddd, MMMM Do, YYYY")}
        </Text>
        <FontAwesome
          name="edit"
          size={19}
          color={Colors[colorScheme].text}
          style={{ margin: 5 }}
        />
      </TouchableOpacity>
      <DateTimePicker
        isVisible={isDatePickerVisible}
        onConfirm={handleDatePicked}
        onCancel={hideDatePicker}
        isDarkModeEnabled={colorScheme == "dark"}
        date={date}
        maximumDate={new Date()}
      />
      <StyledTextInput
        onChangeText={(value) => onChangeWeightNumber(value)}
        keyboardType="numeric"
        returnKeyType="done"
        autoCapitalize="none"
        value={weightNumber}
        placeholder="Weight (lbs)"
      />
      <StyledTextInput
        onChangeText={(value) => onChangeCaloriesNumber(value)}
        keyboardType="numeric"
        returnKeyType="done"
        autoCapitalize="none"
        value={caloriesNumber}
        placeholder="Calories"
      />
      <View style={{ padding: 10, flexDirection: "row" }}>
        <View style={{ flex: 1, alignItems: "center" }}>
          {/* @ts-ignore */}
          <AwesomeButtonRick
            progress
            backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
            backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
            width={Dimensions.get("screen").width - 70}
            onPress={() => {
              if (weightEntry && weightEntry?.id) {
                patchWeightEntryMutation.mutate();
              } else {
                addWeightMutation.mutate();
              }
              goBack();
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                color: colorScheme == "dark" ? "white" : "#202B33",
                fontSize: 18,
              }}
            >
              {weightEntry && weightEntry?.id ? "Update" : "Submit"}
            </Text>
          </AwesomeButtonRick>
          {weightEntry && (
            // @ts-ignore
            <AwesomeButtonRick
              progress
              style={{ marginTop: 15 }}
              backgroundColor={colorScheme == "dark" ? "#9E2B0E" : "#FF7373"}
              backgroundDarker={colorScheme == "dark" ? "#63411E" : "#9E2B0E"}
              width={Dimensions.get("screen").width - 70}
              onPress={() => {
                if (weightEntry) {
                  deleteWeightEntryMutation.mutate();
                }
                goBack();
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: colorScheme == "dark" ? "white" : "#202B33",
                  fontSize: 18,
                }}
              >
                Delete
              </Text>
            </AwesomeButtonRick>
          )}
          <Separator />
          {account.data.pro && (
            <View>
              <Text style={{ fontSize: 20, marginBottom: 15, alignItems: "center" }}>Overwrite?</Text>
              <View style={{ flexDirection: "row", marginBottom: 15 }}>
                <FontAwesome
                  name="question-circle-o"
                  color={Colors[colorScheme].text}
                  size={25}
                  style={{ marginRight: 15 }}
                  onPress={() =>
                    Alert.alert(
                      'If "override" is enabled, then your MyFitnessPal data will overwrite any current calorie or macro data you have inputted in Fitmoh for this date.'
                    )
                  }
                />
                <Switch
                  trackColor={{ false: "#767577", true: "#81b0ff" }}
                  // thumbColor={isSwitchEnabled ? "#f5dd4b" : "#f4f3f4"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={toggleSwitch}
                  value={isSwitchEnabled}
                />
              </View>
              {/* @ts-ignore */}
              <AwesomeButtonRick
                progress
                backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
                backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
                width={Dimensions.get("screen").width - 70}
                onPress={() => {
                  syncMFPDayMutation.mutate();
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    color: colorScheme == "dark" ? "white" : "#202B33",
                    fontSize: 18,
                  }}
                >
                  Sync with MyFitnessPal
                </Text>
              </AwesomeButtonRick>
            </View>
          )}
        </View>
      </View>
      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingTop: "10%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    width: "80%",
    borderRadius: 10,
  },
});
