import moment from 'moment';
import * as React from 'react';
import { RefreshControl, View, StyleSheet, TextInput, useColorScheme, Appearance, ScrollView, TouchableOpacity, Alert, Dimensions } from 'react-native';
import Separator from '../components/Separator';
import { Text } from '../components/Themed';
import WeightChart from '../components/WeightChart';
import AwesomeButtonRick from '../components/rick';
import Colors from '../constants/Colors';
import { FontAwesome } from '@expo/vector-icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from '../api';
import { TokenContext } from '../navigation';
import { useEffect } from 'react';
const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }

export default function MeasurementScreen({navigation, route}) {
    const [refreshing, setRefreshing] = React.useState(false);
    const globalState = React.useContext(TokenContext);
    const token = globalState.userToken;
    const queryClient = useQueryClient();
    const [measurementType, setMeasurementType] = React.useState(route.params);
    useEffect(() => {
      navigation.setOptions({ title: measurementType.title });
    })
    const colorScheme = useColorScheme();

    const currentMeasurements = useQuery([API.getMeasurementType.query_id, measurementType.id], () => API.getMeasurementType.query(measurementType.id));
    const onRefresh = React.useCallback(async () => {
        setRefreshing(true);
        wait(500).then(() => setRefreshing(false));
      }, []);

    const account = useQuery(API.accountInfo.query_id, API.accountInfo.query);
    const deleteMeasurement = useMutation((id) => {
      return API.deleteMeasurement.query(id);
      }, {
      onSuccess: () => { queryClient.refetchQueries([API.getMeasurementType.query_id])},
      onError: (error, variables, context) => {
        console.log('addMeasurementMutation error', error);
      },
    });
    
    return (
        <ScrollView style={{flex: 1}} refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }>
            {<WeightChart data={null || currentMeasurements?.data?.measurements?.map(m => {return {'date': m.date, 'weight': m.measurement}})} />}
            <View style={{alignItems:'center', marginBottom: -20}}>
              {/* @ts-ignore */}
            <AwesomeButtonRick 
              backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
              backgroundDarker={colorScheme == 'dark' ? "#202B33": "#1F4B99"}
              width={Dimensions.get('screen').width - 20}
              onPress={() => navigation.navigate('MeasurementModalScreen', {measurement_type: measurementType})}>
                <Text style={{fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33'}}>Add Measurement</Text>
            </AwesomeButtonRick>
            </View>
            <Separator />
            {currentMeasurements?.data?.measurements?.map(m => (
            <View key={moment(m.date).format("MMMM Do. YYYY") + "-" + String(m.measurement)}>
              
              <TouchableOpacity
                        activeOpacity={0.5}
                        style={{flexDirection: 'row', justifyContent: 'space-between'}}
                        onPress={() =>navigation.navigate('MeasurementModalScreen', {measurementType, 'measurement': m})} 
                        onLongPress={() => Alert.alert('Delete Measurement', "", [{text: 'Delete',  style: 'destructive', onPress: () => deleteMeasurement.mutate(m.id)}, {text: 'Cancel', style: 'cancel'}])}
                    >
                        <Text style={{marginLeft: 15, ...styles.title}}>{moment(m.date).format("MMMM Do, YYYY")}</Text>
                        <Text style={{marginRight: 15, ...styles.title}}>{m.measurement} in.</Text>
                    </TouchableOpacity>
                    <Separator />
            </View>))}
            </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
        fontSize: 20,
    },
});
