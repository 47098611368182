import axios from 'axios';
import { initializeApp } from "firebase/app";
export const devMode = false;
export const URLpointer = devMode ? 'http://localhost:8000/': 'https://api.fitmoh.com/';
export const defaultToken =  'fd15d86272a881f9778a6d0435f72cb74b61230f'; 
export const headers = {
  };

const validate = (token) => axios.get(URLpointer + 'api/validate/', { headers: {'Authorization': 'Token ' + token}}).then((res) => res.data).catch((err) => console.log('validate error:', err));

// Core
const getAccountMe = () => axios.get(URLpointer + 'api/accounts/me/', { headers }).then((res) => res.data).catch((err) => console.log('account me error:', err));;
const getAuthToken = (jwt) => axios.post(URLpointer + 'auth/getToken/', {'firebaseToken': jwt}, {headers}).then((res) => res.data).catch((err) => console.log('getAuthToken error:', err));;
const patchAccountMe = (params) => axios.patch(URLpointer + 'api/accounts/me/', params, { headers }).then((res) => res.data).catch((err) => console.log('patchAccountMe error:', err));;

// Weight
const getWeightData = (page = 26) => axios.get(URLpointer + 'api/weight/data/' + String(page) + '/', { headers }).then((res) => res.data).catch((err) => console.log('getWeightData error:', JSON.stringify(err)));;
const getAllWeightData = () => axios.get(URLpointer + 'api/weight/data/', { headers }).then((res) => res.data).catch((err) => console.log('getAllWeightData error:', err));;
const getWeightList = () => axios.get(URLpointer + 'api/weight/', { headers }).then((res) => res.data).catch((err) => console.log('getWeightList error:', err));;
const addWeightEntry = (date, weight, calories) => axios.post(URLpointer + 'api/weight/create/', {date, weight, calories}, {headers}).then((res) => res.data).catch((err) => console.log('addweightentry error:', err));;
const patchWeightEntry = (id, weight, calories) => axios.patch(URLpointer + 'api/weight/'+id+'/', {weight, calories}, {headers}).then((res) => res.data).catch((err) => console.log('patchweightentry error:', err));;
const deleteWeightEntry = (id) => axios.delete(URLpointer + 'api/weight/'+id+'/', {headers}).then((res) => res.data).catch((err) => console.log('deleteweightentry error:', err));;

// Weight Goals (Blocks)
const getLatestActiveWeightGoal = () => axios.get(URLpointer + 'api/weightgoal/latestactive/', { headers }).then((res) => res.data).catch((err) => console.log('getlatestactiveweightgoal error:', err));;
const getWeightGoal = (id) => axios.get(URLpointer + 'api/weightgoal/'+id+'/', {headers}).then((res) => res.data).catch((err) => console.log('getweightgoal error:', err));;
const getWeightGoals = () => axios.get(URLpointer + 'api/weightgoal/list/', { headers }).then((res) => res.data).catch((err) => console.log('getweightgoals error:', err));;
// params = {target_weight, completed, date_completed}
const patchWeightGoal = (id, params) => axios.patch(URLpointer + 'api/weightgoal/'+id+'/', params, {headers}).then((res) => res.data).catch((err) => console.log('patchweightgoal error:', err));;
const createWeightGoal = (user, targetChangePerWeek, start_date, title) => axios.post(URLpointer + 'api/weightgoal/create/', {user, targetChangePerWeek, start_date, title}, {headers}).then((res)=> res.data).catch((err) => console.log('createweightgoal error:', err));;

// Measurements
const getAllMeasurementTypes = () => axios.get(URLpointer + 'api/measurementtypes/', { headers }).then((res) => res.data).catch((err) => console.log('getAllMeasurementTypes error:', err));;
const getMeasurements = () => axios.get(URLpointer + 'api/measurementtypes/', { headers }).then((res) => res.data).catch((err) => console.log('validate error:', err));;
const getMeasurementType = (id) => axios.get(URLpointer + 'api/measurementtypes/' + id + '/', { headers }).then((res) => res.data).catch((err) => console.log('getMeasurementType error:', err));;
const addMeasurement = (date, measurement, measurement_type) => axios.post(URLpointer + 'api/measurements/create/', {date, measurement, measurement_type}, {headers}).then((res) => res.data).catch((err) => console.log('addMeasurement error:', err));;
const patchMeasurement = (id, measurement) => axios.patch(URLpointer + 'api/measurements/'+id+'/', {measurement}, {headers}).then((res) => res.data).catch((err) => console.log('patchMeasurement error:', err));;
const deleteMeasurement = (id) => axios.delete(URLpointer + 'api/measurements/'+id+'/', {headers}).then((res) => res.data).catch((err) => console.log('deleteMeasurement error:', err));;

// MyFitnessPal API
const syncMFPDay = (date: string, override: boolean) => axios.post(URLpointer + 'api/nutrition/mfp_syncday/', { 'date': date, 'override': override }, {headers}).then((res) => res.data).catch((err) => console.log('syncMFPDay error:', err));;

export const API = {
  validate: {
    query_id: 'validate',
    query: validate
  },

  accountInfo: {
    query_id: 'accountInfo',
    query: getAccountMe
  },

  patchAccountInfo: {
    query_id: 'patchAccountInfo',
    query: patchAccountMe,
  },

  getAuthToken: {
    query_id: 'getAuthToken',
    query: getAuthToken
  },

  weightData: {
    query_id: 'weightData',
    query: getWeightData
  },

  allWeightData: {
    query_id: 'allWeightData',
    query: getAllWeightData
  },

  weightList: {
    query_id: 'weightList',
    query: getWeightList
  },

  latestActiveWeightGoal: {
    query_id: 'latestActiveWeightGoal',
    query: getLatestActiveWeightGoal
  },

  weightGoalList: {
    query_id: 'weightGoalList',
    query: getWeightGoals
  },

  addWeightEntry: {
    query_id: 'addWeightEntry',
    query: addWeightEntry
  },

  patchWeightEntry: {
    query_id: 'patchWeightEntry',
    query: patchWeightEntry
  },

  deleteWeightEntry: {
    query_id: 'deleteWeightEntry',
    query: deleteWeightEntry
  },

  patchWeightGoal: {
    query_id: 'patchWeightGoal',
    query: patchWeightGoal
  },

  getWeightGoal: {
    query_id: 'getWeightGoal',
    query: getWeightGoal
  },

  getAllMeasurementTypes: {
    query_id: 'getAllMeasurementTypes',
    query: getAllMeasurementTypes
  },

  getMeasurementType: {
    query_id: 'getMeasurementType',
    query: getMeasurementType
  },

  addMeasurement: {
    query_id: 'addMeasurement',
    query: addMeasurement
  },

  patchMeasurement: {
    query_id: 'patchMeasurement',
    query: patchMeasurement
  },

  deleteMeasurement: {
    query_id: 'deleteMeasurement',
    query: deleteMeasurement
  },

  syncMFPDay: {
    query_id: 'syncMFPDay',
    query: syncMFPDay
  },
  
  createWeightGoal: {
    query_id: 'createWeightGoal',
    query: createWeightGoal
  }
}

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDc8Flj-Q9w4jpnsFPubxw-cKoC6m2XOpk",
  authDomain: "fitmoh.firebaseapp.com",
  projectId: "fitmoh",
  storageBucket: "fitmoh.appspot.com",
  messagingSenderId: "721411193159",
  appId: "1:721411193159:web:dc1eaa9f6158d749e1b462",
  measurementId: "G-HSBN5MLZSS"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);