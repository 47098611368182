import * as React from "react";
import {
  StyleSheet,
  FlatList,
  ActivityIndicator,
  useColorScheme,
  Dimensions,
} from "react-native";

import { Text, View } from "../components/Themed";

import { useQuery, useQueryClient } from "react-query";

import { API } from "../api";
import WeeklyCard from "../components/WeeklyCard";
import { TokenContext } from "../navigation";
import WeightChart from "../components/WeightChart";
import AwesomeButtonRick from "../components/rick";

export default function WeightListScreen({ navigation }) {

  const globalState = React.useContext(TokenContext);
  const token = globalState.userToken;
  // const weightDataQuery = useQuery(API.weightData.query_id, API.weightData.query);
  const [page, setPage] = React.useState(26); 
  const [loading, setLoading] = React.useState(false);
  const weightData = useQuery([API.weightData.query_id, page], () => API.weightData.query(page), {keepPreviousData: true})
  const [chartVisible, setChartVisible] = React.useState(false);
  const queryClient = useQueryClient();
  const colorScheme = useColorScheme();

  if (weightData.isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (weightData.isError) {
    console.error("error on weight list screen:", weightData.error);
    // @ts-ignore
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Error: {weightData.error.message}</Text>
      </View>
    );
  }
  const chartData = weightData?.data
    ?.map((weekItem) => weekItem.year == 2021 && weekItem.objects)
    .flat()
    .sort((first, second) => first.date > second.date);
  return (
    <View
      style={
        weightData.data.length > 0 ? styles.container : styles.containerNull
      }
    >
      {/* @ts-ignore */}
      {weightData.data.length > 0 ? (
        <FlatList
          onEndReached={() => {
            setLoading(true);
            setPage(10000)
          }}
          initialNumToRender={6}
          data={weightData.data as any[]}
          // ListFooterComponent={loading && <ActivityIndicator size="large" style={{marginBottom: 50}} />}
          ListHeaderComponent={
            <View style={{ alignItems: "center", marginTop: 15 }}>
              {/* @ts-ignore */}
              <AwesomeButtonRick
                backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
                backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
                width={Dimensions.get("screen").width - 75}
                onPress={() => setChartVisible(!chartVisible)}
                height={50}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: colorScheme == "dark" ? "white" : "#202B33",
                  }}
                >
                  {chartVisible ? "Hide" : "View"} Chart
                </Text>
              </AwesomeButtonRick>
              {chartVisible && <WeightChart data={chartData} />}
            </View>
          }
          renderItem={({ item }) => (
            <WeeklyCard
              entry={item}
              navigation={navigation}
              queryClient={queryClient}
            />
          )}
          keyExtractor={(item) => `entry-${item.week}-${item.year}`}
        />
      ) : (
        <Text style={{ fontSize: 30, textAlign: "center" }}>
          Start logging entries for something to show up here.
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerNull: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
