import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { TouchableOpacity, StyleSheet, useColorScheme, Alert } from "react-native";
import { View, Text } from "../components/Themed";
import Colors from "../constants/Colors";
import Separator from '../components/Separator';
import { AuthContext } from "../navigation";
import { getAuth } from "firebase/auth";
import { useQueryClient } from "react-query";
const auth = getAuth();
export default function SettingsScreen({navigation, route}) {
    const colorScheme = useColorScheme();
    const {signOut} = React.useContext(AuthContext);
    const [refreshing, setRefreshing] = React.useState(false);
    const queryClient= useQueryClient();
    return (
        <View style={{flex: 1, paddingTop: 30}}>
            <><TouchableOpacity
                        activeOpacity={0.5}
                        style={{flexDirection: 'row', justifyContent: 'space-between'}}
                        onPress={() => Alert.alert("You need to be a member of the Fitmoh beta to use this feature.")}
                    >
                        <Text style={styles.title}>Connect to MyFitnessPal</Text>
                        <FontAwesome 
                            name="chevron-right" 
                            size={20}
                            color={Colors[colorScheme].text}
                            style={{ marginRight: 15 }} />
                    </TouchableOpacity>
                    <Separator /></>
                    <TouchableOpacity
                        activeOpacity={0.5}
                        style={{flexDirection: 'row', justifyContent: 'space-between'}}
                        onPress={() => {
                            setRefreshing(true);
                            queryClient.refetchQueries().then(q => {
                                setRefreshing(false);
                            });
                        }}
                    >
                        <Text style={styles.title}>{refreshing ? "Refreshing..." : "Refresh App"}</Text>
                        <FontAwesome 
                            name="refresh" 
                            size={20}
                            color={Colors[colorScheme].text}
                            style={{ marginRight: 15 }} />
                    </TouchableOpacity>
                    <Separator />
                    <TouchableOpacity
                        activeOpacity={0.5}
                        style={{flexDirection: 'row', justifyContent: 'space-between'}}
                        onPress={() => {auth.signOut(); signOut()}}
                    >
                        <Text style={styles.title}>Sign out</Text>
                        <FontAwesome 
                            name="sign-out" 
                            size={20}
                            color={Colors[colorScheme].text}
                            style={{ marginRight: 15 }} />
                    </TouchableOpacity>
                    <Separator />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 30,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginLeft: 15
    },
});