import * as React from 'react';
import { useColorScheme, TextInput } from 'react-native';
function StyledTextInput(props) {
    const colorScheme = useColorScheme();
    const value = props.value;
    const textSize = props.textSize || 35;
    return (
        <TextInput 
            style={{
                fontSize: value == '' ? 25 : textSize,
                height: 60,
                margin: 12,
                borderWidth: 2,
                padding: 10,
                width: '80%',
                borderRadius: 10,
                textAlign: 'center',
                color: colorScheme == 'dark' ? "white" : 'black',
                borderColor: colorScheme == 'dark' ? 'gray' : 'black',
                ...props.style
              }}
            {...props} />
    )
}

export default StyledTextInput;