import moment from "moment";
import * as React from "react";
import {
  RefreshControl,
  StyleSheet,
  TextInput,
  useColorScheme,
  Pressable,
  Dimensions,
  Appearance,
  Modal,
  ScrollView,
  TouchableOpacity,
  Button,
  Alert,
} from "react-native";
import Separator from "../components/Separator";
import { Text, View } from "../components/Themed";
import WeeklyCard from "../components/WeeklyCard";
import WeightChart from "../components/WeightChart";
import Colors from "../constants/Colors";
import { FontAwesome } from "@expo/vector-icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API } from "../api";
import { Slider } from "@miblanchard/react-native-slider";
import { TokenContext } from "../navigation";
import { useEffect } from "react";
import { ProgressChart } from "react-native-chart-kit";
import AwesomeButtonRick from "../components/rick";
import DateTimePicker from "react-native-modal-datetime-picker";
import Toast from "react-native-toast-message";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};
const getOrNull = (value) => {
  return value == "" ? null : value;
};

export default function BlockScreen({ navigation, route }) {
  const [refreshing, setRefreshing] = React.useState(false);
  const globalState = React.useContext(TokenContext);
  const token = globalState.userToken;
  const queryClient = useQueryClient();
  const [block, setBlock] = React.useState(route.params);
  useEffect(() => {
    navigation.setOptions({ title: block.title });
    if (!block.completed) {
      navigation.setOptions({
        headerRight: () => 
          <Pressable onPress={() =>
            updateWeightGoal.mutate({
              target_weight: getOrNull(inputVal),
              completed: true,
              date_completed: moment().format("YYYY-MM-DD"),
            })}><Text style={{textAlign: "center"}}>Mark {"\n"} Complete</Text></Pressable>
      });
    }
  });
  const [isEditing, changeIsEditing] = React.useState(false);
  const [inputVal, changeInputVal] = React.useState(block.target_weight || "");
  const [showWeeks, setShowWeeks] = React.useState(false);
  const [weeklyPace, setWeeklyPace] = React.useState<Number>(
    Number(block.average_change_per_week || block.targetChangePerWeek)
  );
  const [startDate, setStartDate] = React.useState(
    moment(block.start_date).toDate()
  );
  const [DPVisible, setDPVisible] = React.useState(false);
  const colorScheme = useColorScheme();

  const [modalVisible, setModalVisible] = React.useState(false);

  const currentBlock = useQuery([API.getWeightGoal.query_id, block.id], () =>
    API.getWeightGoal.query(block.id)
  );
  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    queryClient.refetchQueries([API.getWeightGoal.query_id]);
    if (currentBlock.data && currentBlock.isSuccess) {
      setBlock(currentBlock.data);
      setRefreshing(false);
    }
  }, []);

  const getTimeDifference = () => {
    const start = moment(startDate);
    let end = block.completed ? moment(block.date_completed) : moment();
    return end.diff(start, "weeks");
  };
  const account = useQuery(API.accountInfo.query_id, API.accountInfo.query);

  const tdees = block.week_info.map(
    (entry) => entry.avg_calories - entry.difference * 500
  );
  const average = (array) =>
    (array.reduce((a, b) => a + b) / array.length)?.toFixed(0);

  const updateWeightGoal = useMutation(
    (params: Object) => {
      Toast.show({type: 'error', text1: 'Updating goal...'})
      return API.patchWeightGoal.query(block.id, params);
    },
    {
      onSuccess: (newBlock) => {
        setBlock(newBlock);
        setStartDate(moment(newBlock.start_date).toDate());
        queryClient.refetchQueries();
        Toast.show({type: 'success', text1: 'Goal updated!'})
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );

  const ending_weight =
    block?.ending_avg_weight || block?.week_info[0]?.avg_weight;
  const change = ending_weight - block.starting_avg_weight;
  const progress = Math.min(
    1,
    change / (Number(inputVal) - block.starting_avg_weight)
  );

  React.useEffect(() => {
    if (block.completed) {
      navigation.setOptions({
        headerRight: null
      })
    }
  }, [block])


  return (
    <ScrollView
      style={{ flex: 1 }}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    >
      <WeightChart data={block.weight_entries} />
      {
        <ProgressChart
          data={{
            data: [inputVal != "" ? progress : 1],
          }}
          width={Dimensions.get("screen").width}
          height={125}
          strokeWidth={16}
          radius={50}
          chartConfig={{
            backgroundGradientFromOpacity: colorScheme == "dark" ? 0 : 1,
            backgroundGradientToOpacity: colorScheme == "dark" ? 0 : 1,
            backgroundGradientTo: colorScheme == "dark" ? "#2a2a2b" : "white",
            backgroundGradientFrom: colorScheme == "dark" ? "#2a2a2b" : "white",
            decimalPlaces: 1, // optional, defaults to 2dp
            color: (opacity = 1) =>
              colorScheme == "dark"
                ? `rgba(202, 202, 217, ${opacity})`
                : `rgba(36, 54, 101, ${opacity})`,
            style: {
              borderRadius: 16,
            },
          }}
          hideLegend={true}
        />
      }
      <View style={{ flexDirection: "column" }}>
        <Text
          style={{
            marginLeft:
              Math.abs(change) >= 10
                ? Dimensions.get("screen").width / 2 - 29
                : Dimensions.get("screen").width / 2 - 25,
            marginTop: -82,
            paddingBottom: 50,
            fontSize: 25,
          }}
        >
          {change < 0 ? "" : "+"}
          {change.toFixed(1)}
        </Text>
        <Text
          style={{
            marginLeft: Dimensions.get("screen").width / 2 - 10,
            marginTop: -50,
            paddingBottom: block.completed ? 30 : 50,
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          lbs
        </Text>
        {!block.completed && (
          <Text
            style={{
              marginLeft: Dimensions.get("screen").width / 2 - 115,
              marginTop: -10,
              paddingBottom: 0,
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            You're {(progress * 100).toFixed(0)}% of the way to your goal.
          </Text>
        )}
        <View
          style={{
            marginTop: 20,
            height: 1,
            width: "100%",
          }}
          lightColor="#eee"
          darkColor="rgba(255,255,255,0.1)"
        />
      </View>

      <View style={styles.container}>
        <TouchableOpacity
          activeOpacity={0.5}
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <Text style={{ marginLeft: 15, fontSize: 20 }}>Weight Change</Text>
          <View
            style={{
              flexDirection: "row",
              marginRight: 10,
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 20 }}>
              {block.starting_avg_weight?.toFixed(1)} {"->"}{" "}
              {block?.ending_avg_weight?.toFixed(1) ||
                block.week_info[0]?.avg_weight?.toFixed(1)}{" "}
              lbs
            </Text>
          </View>
        </TouchableOpacity>
        <Separator />
        <TouchableOpacity
          activeOpacity={0.5}
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: -10,
          }}
          onPress={() => changeIsEditing(!isEditing)}
        >
          <Text style={{ marginLeft: 15, fontSize: 20 }}>Goal Weight</Text>
          <View style={{ flexDirection: "row" }}>
            {isEditing ? (
              <>
                <TextInput
                  style={{
                    color: colorScheme == "dark" ? "white" : "black",
                    ...styles.input,
                  }}
                  autoFocus
                  value={inputVal}
                  onChangeText={changeInputVal}
                  placeholder={"Enter Goal Weight"}
                  returnKeyType={"done"}
                  keyboardType={"numeric"}
                />
                <Button
                  title="Save"
                  onPress={() => {
                    updateWeightGoal.mutate({
                      completed: block.completed,
                      target_weight: getOrNull(inputVal),
                    });
                    changeIsEditing(false);
                  }}
                />
              </>
            ) : (
              <>
                <Text style={{ fontSize: 20 }}>{inputVal || "N/A"} lbs</Text>
                <FontAwesome
                  name="edit"
                  size={20}
                  color={Colors[colorScheme].text}
                  style={{ margin: 10, marginTop: 3 }}
                />
              </>
            )}
          </View>
        </TouchableOpacity>
        <Separator />
        <DateTimePicker
          isVisible={DPVisible}
          onConfirm={(date) => {
            setStartDate(date);
            setDPVisible(false);
            updateWeightGoal.mutate({
              start_date: moment(date).format("YYYY-MM-DD"),
            });
          }}
          onCancel={() => setDPVisible(false)}
          isDarkModeEnabled={colorScheme == "dark"}
          date={startDate}
          maximumDate={new Date()}
        />
        <TouchableOpacity
          activeOpacity={0.5}
          style={{ flexDirection: "column" }}
          onPress={() => {
            // updateWeightGoal.mutate({
            //   start_date: "2022-01-05",
            // })
            setDPVisible(true);
          }}
        >
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={{ marginLeft: 15, fontSize: 20, marginTop: 28 }}>
              Time
            </Text>
            <View
              style={{
                flexDirection: "column",
                marginRight: 10,
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 20 }}>{getTimeDifference()} weeks</Text>
              <View
                style={{
                  marginVertical: 5,
                  height: 1,
                  width: "100%",
                }}
                lightColor="#eee"
                darkColor="rgba(255,255,255,0.2)"
              />
              <Text style={{ fontSize: 14 }}>
                {moment(startDate).format("MMMM Do")}
              </Text>
              <Text>&#8595;</Text>
              <Text style={{ fontSize: 14 }}>
                {block.completed
                  ? moment(block.date_completed).format("MMMM Do")
                  : "now"}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
        <Separator />

        <TouchableOpacity
          activeOpacity={0.5}
          style={{ flexDirection: "row", justifyContent: "space-between" }}
          onPress={() => setModalVisible(true)}
        >
          <Text style={{ marginLeft: 15, fontSize: 20 }}>Target Change</Text>
          <Text style={{ marginRight: 10, fontSize: 20 }}>
            {block.targetChangePerWeek >= 0 ? "+" : null}
            {block.targetChangePerWeek} lbs/week
          </Text>
        </TouchableOpacity>
        <Text
          style={{
            marginLeft: Dimensions.get("screen").width / 2,
            fontSize: 20,
            paddingVertical: 10,
            color: "gray",
          }}
        >
          vs
        </Text>

        <TouchableOpacity
          activeOpacity={0.5}
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <Text style={{ marginLeft: 15, fontSize: 20 }}>Actual Change</Text>
          <Text
            style={{
              marginRight: 10,
              fontSize: 20,
              color:
                Math.abs(
                  Math.abs(block.average_change_per_week) -
                  Math.abs(block.targetChangePerWeek)
                ) > 0.05
                  ? "#F55656"
                  : "#15B371",
            }}
          >
            {block.average_change_per_week >= 0 ? "+" : null}
            {block.average_change_per_week?.toFixed(2)} lbs/week
          </Text>
        </TouchableOpacity>
        <Separator />

        {/* <Text style={styles.title}>Average TDEE: {average(tdees)} calories</Text> */}
        {/* <View style={{ flexDirection: 'row' }}>
                    {isEditing ?
                        (<><TextInput
                            style={{ color: colorScheme == 'dark' ? "white" : 'black', ...styles.input }}
                            autoFocus
                            value={inputVal}
                            onChangeText={changeInputVal}
                            placeholder={"Enter Goal Weight"}
                            returnKeyType={"done"}
                            keyboardType={"numeric"} />
                            <Button title="Save" onPress={() => { updateWeightGoal.mutate(block.completed); changeIsEditing(false) }} />
                        </>) :
                        (<><Text style={styles.title}>{inputVal || "N/A"} lbs</Text>
                            <FontAwesome
                                name="edit"
                                size={20}
                                color={Colors[colorScheme].text}
                                style={{ margin: 10, marginTop: 3 }}
                                onPress={() => changeIsEditing(!isEditing)}
                            /></>)}
                </View> */}
        <View style={{ alignItems: "center" }}>
          {inputVal && !block.completed ? (
            <>
              <TouchableOpacity
                onPress={() =>
                  setWeeklyPace(
                    block.average_change_per_week || block.targetChangePerWeek
                  )
                }
              >
                <Text
                  style={{
                    padding: 25,
                    paddingBottom: 0,
                    textAlign: 'center'
                  }}
                >
                  At a pace of {weeklyPace?.toFixed(2)} lbs/week, you will reach
                  your goal in approximately{" "}
                  {(
                    (inputVal - account.data.average_weight) /
                    weeklyPace
                  )?.toFixed(0)}{" "}
                  weeks on{" "}
                  {moment()
                    .add(
                      ((inputVal - account.data.average_weight) / weeklyPace) * 7,
                      "days"
                    )
                    .format("MMMM D, YYYY")}
                  .
                </Text>
              </TouchableOpacity>
              <View style={{ width: "80%" }}>
                {
                  <Slider
                    value={weeklyPace}
                    onValueChange={(value) => setWeeklyPace(value[0])}
                    step={0.05}
                    disabled={false}
                    minimumValue={-3}
                    maximumValue={3}
                  />
                }
              </View>
              <Text
                style={{
                  padding: 25,
                  paddingBottom: 10,
                  textAlign: 'center'
                }}
              >
                To gain {weeklyPace?.toFixed(2)} lbs/week, you will need to eat
                around {(account.data.tdee + 500 * weeklyPace).toFixed(0)}{" "}
                calories per day based on a current TDEE of{" "}
                {account.data.tdee?.toFixed(0)}.
              </Text>
            </>
          ) : null}
        </View>
      </View>
      <View style={{ alignItems: "center" }}>
        {/* @ts-ignore */}
        {false && (
          <AwesomeButtonRick
            backgroundColor={colorScheme == "dark" ? "#30404D" : "#3DCC91"}
            backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1D7324"}
            width={Dimensions.get("screen").width - 10}
            onPress={() =>
              updateWeightGoal.mutate({
                target_weight: getOrNull(inputVal),
                completed: true,
                date_completed: moment().format("YYYY-MM-DD"),
              })
            }
            style={{ marginBottom: 15 }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: colorScheme == "dark" ? "white" : "#202B33",
              }}
            >
              Mark as Completed
            </Text>
          </AwesomeButtonRick>
        )}
        {/* @ts-ignore */}
        <AwesomeButtonRick
          backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
          backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
          width={Dimensions.get("screen").width - 10}
          onPress={() => setShowWeeks(!showWeeks)}
          style={{ marginBottom: 15 }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              color: colorScheme == "dark" ? "white" : "#202B33",
            }}
          >
            {(showWeeks ? "Hide" : "Show") + " Week by Week"}
          </Text>
        </AwesomeButtonRick>
      </View>
      {showWeeks
        ? block.week_info.map((item) => (
          <WeeklyCard
            entry={item}
            key={`entry-${item.week}-${item.year}`}
            navigation={navigation}
          />
        ))
        : null}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  input: {
    fontSize: 20,
    height: 25,
    margin: 10,
    marginTop: 2,
    borderBottomWidth: 1,
    color: Appearance.getColorScheme() == "dark" ? "white" : "black",
    borderRadius: 10,
  },
  centeredView: {
    flex: 0.5,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});
