import * as React from "react";
import { StyleSheet, Button, TextInput, useColorScheme, Pressable, Platform } from "react-native";

import { View, Text } from "../components/Themed";
import StyledTextInput from "../components/StyledTextInput";

import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import * as Facebook from "expo-auth-session/providers/facebook";
import * as AppleAuthentication from 'expo-apple-authentication';
import { ResponseType } from "expo-auth-session";

import { API, firebaseConfig } from "../api";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  sendPasswordResetEmail
} from "firebase/auth";
import AwesomeButtonRick from "../components/rick";
import Separator from "../components/Separator";
import { AuthContext } from "../navigation/index";
import { FontAwesome } from "@expo/vector-icons";
import Colors from "../constants/Colors";
import Toast from 'react-native-toast-message';
import { AppleAuthenticationButtonStyle } from "expo-apple-authentication";

initializeApp(firebaseConfig);
WebBrowser.maybeCompleteAuthSession();
const auth = getAuth();
const provider = new OAuthProvider('apple.com');
provider.addScope('email');

export default function LoginScreen({ navigation }) {
  const [email, editEmail] = React.useState("");
  const [password, editPassword] = React.useState("");
  const { signIn } = React.useContext(AuthContext);
  const [cred, setCred] = React.useState("");
  const colorScheme = useColorScheme();
  const [page, setPage] = React.useState("Base");
  const [gRequest, gResponse, googleLogin] = Google.useIdTokenAuthRequest({
    clientId:
      Platform.OS == "ios" ? "721411193159-crakdo7e487qalmb0bkgjirbolnklsd5.apps.googleusercontent.com" : "721411193159-2f559kf508hj6lkjlb2srpdks4nr4s4e.apps.googleusercontent.com",
  });
  const [fbRequest, fbResponse, fbLogin] = Facebook.useAuthRequest({
    responseType: ResponseType.Token,
    clientId: "598448211428338",
  });

  React.useEffect(() => {
    if (fbResponse?.type === "success") {
      const { access_token } = fbResponse.params;

      const credential = FacebookAuthProvider.credential(access_token);
      // Sign in with the credential from the Facebook user.
      signInWithCredential(auth, credential).then((res) => {
        signIn(res.user).then();
      });
    }
  }, [fbResponse]);

  React.useEffect(() => {
    if (gResponse?.type === "success") {
      const { id_token } = gResponse.params;

      const credential = GoogleAuthProvider.credential(id_token);
      signInWithCredential(auth, credential).then((res) => {
        signIn(res.user).then();
      });
    }
  }, [gResponse]);

  const appleSignin = (credential) => {
    if (credential.email == null) {
      Toast.show({type: "error", text1: "Action failed. Please try another sign-up method."});
    } else {
      if (page == "Sign Up") {
        createUserWithEmailAndPassword(auth, credential.email, credential.user)
        .then((res) => signIn())
        .catch((err) => { console.log("error signing in with Apple", err); Toast.show({ type: 'error', text1: "Couldn't sign up!" }) })
      } else {
        signInWithEmailAndPassword(auth, credential.email, credential.user)
        .then((res) => signIn())
        .catch((err) => { console.log("error signing in with Apple", err); Toast.show({ type: 'error', text1: "Couldn't sign in!" }) })
      }
    }
  }

  const resetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        Toast.show({type: 'success', text1: 'Password reset email sent!'})
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }

  // Warm Up Web Browser
  // React.useEffect(() => {
  //     WebBrowser.warmUpAsync();

  //     return () => {
  //       WebBrowser.coolDownAsync();
  //       };
  //   }, []);
  if (page == "Base") {
    navigation.setOptions({ headerLeft: null })
    return (
      <View style={{ justifyContent: "center", alignItems: "center", paddingTop: 50 }}>
        <AwesomeButtonRick
          backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
          backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
          width={300}
          style={{ marginBottom: 25 }}
          onPress={() =>
            setPage("Sign In")
          }
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              color: colorScheme == "dark" ? "white" : "#202B33",
            }}
          >
            Login
          </Text>
        </AwesomeButtonRick>
        <AwesomeButtonRick
          backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
          backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
          width={300}
          onPress={() =>
            setPage("Sign Up")
          }
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              color: colorScheme == "dark" ? "white" : "#202B33",
            }}
          >
            Sign Up
          </Text>
        </AwesomeButtonRick>
      </View>
    )
  } else {
    navigation.setOptions({
      headerLeft: () => <Pressable hitSlop={45} onPress={() => setPage("Base")}
      ><FontAwesome
          name="arrow-left"
          size={25}
          color={Colors[colorScheme].text}
          style={{ margin: 10, marginTop: 8 }}
        /></Pressable>
    })
  }


  return (
    <View style={styles.container}>
      {/* <Text style={{width: '80%'}}>{JSON.stringify(auth.currentUser)}</Text> */}
        {/* <Text>{JSON.stringify(cred)}</Text> */}
      <StyledTextInput
        textSize={16}
        value={email}
        onChangeText={editEmail}
        autoComplete={"email"}
        keyboardType="email-address"
        textContentType="emailAddress"
        placeholder="Email"
        returnKeyType="done"
      />
      {page != "Password Reset" && <StyledTextInput
        textSize={16}
        value={password}
        onChangeText={editPassword}
        placeholder="Password"
        returnKeyType="done"
        secureTextEntry
        textContentType="password"
      />}
      {/* <Button title="Test Sign In" onPress={() => signIn()} /> */}
      {/* @ts-ignore */}
      {page == "Sign In" && <AwesomeButtonRick
        backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
        width={300}
        style={{marginBottom: 10}}
        onPress={() =>
          signInWithEmailAndPassword(auth, email, password)
            .then((res) => signIn())
            .catch((err) => { console.log("error signing in with email/pass", err); Toast.show({ type: 'error', text1: "Couldn't log in!" }) })
        }
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: colorScheme == "dark" ? "white" : "#202B33",
          }}
        >
          Sign In
        </Text>
      </AwesomeButtonRick>}
      {page == "Sign In" && <AwesomeButtonRick
        backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
        width={300}
        onPress={() =>
          setPage("Password Reset")
        }
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: colorScheme == "dark" ? "white" : "#202B33",
          }}
        >
          Forgot Password?
        </Text>
      </AwesomeButtonRick>}
      {page == "Password Reset" && <AwesomeButtonRick
        backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
        width={300}
        onPress={() =>
          resetPassword()
        }
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: colorScheme == "dark" ? "white" : "#202B33",
          }}
        >
          Reset Password
        </Text>
      </AwesomeButtonRick>}
      {page == "Sign Up" && <AwesomeButtonRick
        backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
        width={300}
        onPress={() =>
          createUserWithEmailAndPassword(auth, email, password)
            .then((res) => signIn())
            .catch((err) => { console.log("error signing in with email/pass", err); Toast.show({ type: 'error', text1: "Couldn't sign up!" }) })
        }
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: colorScheme == "dark" ? "white" : "#202B33",
          }}
        >
          Sign Up
        </Text>
      </AwesomeButtonRick>}
      <Separator />
      {/* @ts-ignore */}
      {page != "Password Reset" && <AwesomeButtonRick
        backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
        width={300}
        disabled={!gRequest}
        onPress={() => {
          googleLogin();
        }}
        style={{ marginBottom: 15 }}
      >
        <FontAwesome
          name="google"
          size={25}
          color={Colors[colorScheme].text}
          style={{ marginRight: 15 }}
        />
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: colorScheme == "dark" ? "white" : "#202B33",
          }}
        >
          {page} With Google
        </Text>
      </AwesomeButtonRick>}
      {/* @ts-ignore */}
      {/* <AwesomeButtonRick
        backgroundColor={colorScheme == "dark" ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == "dark" ? "#202B33" : "#1F4B99"}
        width={300}
        disabled={!fbRequest}
        style={{marginBottom: 25}}
        onPress={() => fbLogin()}
      >
        <FontAwesome
          name="facebook"
          size={25}
          color={Colors[colorScheme].text}
          style={{ marginRight: 15 }}
        />
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: colorScheme == "dark" ? "white" : "#202B33",
          }}
        >
          {page} with Facebook
        </Text>
      </AwesomeButtonRick> */}
      {page != "Password Reset" && <AppleAuthentication.AppleAuthenticationButton
        buttonType={page == "Sign Up" ? AppleAuthentication.AppleAuthenticationButtonType.SIGN_UP : AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
        cornerRadius={50}
        style={{ width: 300, height: 55 }}
        onPress={async () => {
          try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
                // AppleAuthentication.AppleAuthenticationScope.
              ],
            });
            setCred(JSON.stringify(credential));
            appleSignin(credential);
          } catch (e) {
            if (e.code === 'ERR_CANCELED') {
              // handle that the user canceled the sign-in flow
            } else {
              // handle other errors
            }
          }
        }}
      />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    // justifyContent: 'center',
    paddingTop: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    width: "80%",
    borderRadius: 10,
  },
});
