import * as React from 'react';
import { ActivityIndicator, Alert, Dimensions, Platform, Pressable, StyleSheet, TouchableOpacity, useColorScheme } from 'react-native';

import { Text, View, ScrollView } from '../components/Themed';
import { RootTabScreenProps } from '../types';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import WeightChart from '../components/WeightChart';

import { API } from '../api';
import { AuthContext, TokenContext } from '../navigation';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../constants/Colors';
import StyledTextInput from '../components/StyledTextInput';
import { Picker } from '@react-native-picker/picker';
import DateTimePicker from 'react-native-modal-datetime-picker';
import moment from 'moment';
import AwesomeButtonRick from '../components/rick';
import Separator from '../components/Separator';
import { getAuth } from "firebase/auth";
import { AccountInfo, WeightGoal } from '../types'
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';

const medicalSourcesText = "Our Total Daily Energy Expenditure (TDEE) calculation aims to tell you the amount of calories needed to maintain your weight. We calculate your target calories by multiplying 3500 calories (# of calories equal to a pound of weight) with the number of pounds per week you want to gain/lose. For medical sources on these calculations, please visit fitmoh.com/sources for more details."

const getActivityLevel = (level) => {
  if (level == 1.2) {
    return "Sedentary - Little to no exercise w/ desk job";
  } else if (level == 1.375) {
    return "Lightly Active - light cardio/sports 1-3 days/week"
  } else if (level == 1.55) {
    return "Moderately Active - moderate cardio/sports 6-7 days/week";
  } else if (level == 1.725) {
    return "Very Active - hard cardio/sports daily";
  } else {
    return "Extremely Active - hard cardio/sports 2+ times/day"
  }
}

const calculateTDEE = (gender: string, weight: number, height: number, age: number, activityLevel: number) => {
  let BMR = 0;
  if (gender === 'Male') {
    BMR = 66 + (13.7 * (weight / 2.205)) + (5 * height * 2.54) - (6.8 * age)
  } else {
    BMR = 655 + (9.6 * (weight / 2.205)) + (1.8 * height * 2.54) - (4.7 * age)
  }
  return String((BMR * activityLevel).toFixed(0));
}

const auth = getAuth();

export default function HomeScreen({ navigation }: RootTabScreenProps<'Home'>) {
  const globalState = React.useContext(TokenContext);
  const {signOut} = React.useContext(AuthContext);
  const token = globalState.userToken;
  const colorScheme = useColorScheme();
  const titleColor = colorScheme == 'dark' ? '#A7B6C2' : '#5C7080';
  // STATE
  const [gender, setGender] = React.useState("Male");
  const [recentWeight, setRecentWeight] = React.useState("");
  const [activityLevel, setActivityLevel] = React.useState("1.375");
  const [showPicker, setShowPicker] = React.useState(false);
  const [heightFeet, setHeightFeet] = React.useState("5");
  const [heightInches, setHeightInches] = React.useState("7");
  const [isDatePickerVisible, setDatePickerVisibility] = React.useState(false);
  const [date, setDate] = React.useState(new Date(new Date().setFullYear(new Date().getFullYear() - 18)));
  const [pageNum, setPageNum] = React.useState(1);
  const [goalTitle, setGoalTitle] = React.useState("");
  const [goal, setGoal] = React.useState("Maintain"); // Maintain, Cut, Bulk
  const [targetChange, setTargetChange] = React.useState("0");
  const [mfpUsername, setMfpUsername] = React.useState("");
  const [mfpPassword, setMfpPassword] = React.useState("");
  // QUERIES
  const { isLoading: accountIsLoading, isError: accountIsError, data, error } = useQuery(API.accountInfo.query_id, API.accountInfo.query);
  const info = useQuery([API.weightData.query_id, 26], () => API.weightData.query(12));
  const latestActiveWeightGoalQuery = useQuery<any, any>(API.latestActiveWeightGoal.query_id, API.latestActiveWeightGoal.query);
  const latestActiveWeightGoalData = latestActiveWeightGoalQuery.data as WeightGoal;
  const checkValidate = useQuery(API.validate.query_id, () => API.validate.query(token));
  const queryClient = useQueryClient();
  const addWeightMutation = useMutation(
    () => {
      return API.addWeightEntry.query(
        moment(new Date()).format("YYYY-MM-DD"),
        Number(recentWeight),
        null
      );
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries();
      },
      onError: (error, variables, context) => {
        console.log("addWeightMutation error", error);
      },
    }
  );

  const createGoalMutation = useMutation(
    () => {
      return API.createWeightGoal.query(
        account.userid,
        targetChange,
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries();
      },
      onError: (error, variables, context) => {
        console.log("createGoalMutation error", error);
      },
    }
  );

  const patchAccountMe = useMutation(
    () => {
      return API.patchAccountInfo.query(
        {
          birth_date: moment(date).format("YYYY-MM-DD"),
          gender,
          height: (Number(heightFeet) * 12) + Number(heightInches),
          activityLevel: Number(activityLevel),
        }
      )
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries();
      },
      onError: (error, variables, context) => {
        console.log("patchAccountMe error", error);
      },
    }
  );

  React.useEffect(() => {
    (async () => {
      const { status } = await requestTrackingPermissionsAsync();
      if (status === 'granted') {
        console.log('Granted.');
      }
    })();
  }, []);

  if (checkValidate.isLoading != true && checkValidate?.data?.detail == "Invalid token.") {
    auth.signOut();
    signOut();
  }

  if (accountIsLoading) {
    return <View style={{ justifyContent: 'center', ...styles.container }}><ActivityIndicator size="large" /></View>
  }

  if (accountIsError) {
    console.error('error on homescreen:', error);
    console.log('token:', 'Token ' + token);
    // @ts-ignore
    return <Text>Error: {error.message}</Text>
  }
  const account = data as AccountInfo;

  const handleDatePicked = (date) => {
    setDate(date);
    setDatePickerVisibility(false);
  };

  const getTDEE = (returnNum = false) => {
    if (account?.tdee) return account?.tdee;
    let tdee = 0;
    let count = 0
    // @ts-expect-error
    for (let i = 0; i < Math.min(5, info.data?.length); i++) {
      let item = info.data[i];
      let weekTDEE = item.avg_calories ? (item.avg_calories - (item.difference * 500)) : null;
      if (weekTDEE != null) {
        tdee += weekTDEE;
        count += 1;
      }
    }
    if (returnNum) {
      return tdee / count;
    }
    return (tdee / count).toFixed(0);
  }
  let renderGoalChangePerWeek = null;
  let targetCalories = null;
  let targetCals = null;
  if (latestActiveWeightGoalQuery.isSuccess && latestActiveWeightGoalData) {

    renderGoalChangePerWeek = <Text style={styles.title}>Weekly Goal Target: {latestActiveWeightGoalData.targetChangePerWeek >= 0 ? "+" : null}{latestActiveWeightGoalData.targetChangePerWeek} lbs</Text>
    targetCalories = <Text style={{ fontSize: 30, marginTop: 15 }}>Target Calories: {((latestActiveWeightGoalData.targetChangePerWeek * 500) + Number(getTDEE(true)))?.toFixed(0) || 'N/A'}</Text>
    targetCals = ((latestActiveWeightGoalData.targetChangePerWeek * 500) + Number(getTDEE(true)))?.toFixed(0) || 'N/A';
  }
  queryClient.prefetchQuery(API.weightGoalList.query_id, API.weightGoalList.query);
  queryClient.prefetchQuery(API.getAllMeasurementTypes.query_id, API.getAllMeasurementTypes.query);
  const activeGoal = latestActiveWeightGoalData && Object.keys(latestActiveWeightGoalData).length > 0;
  /* 
    Onboarding flow:
      - Need gender, height, activity level
      - Need recent weight
      - Need birthday
    Then, calculate ESTIMATED TDEE. 
    "This is your current estimated TDEE. As you log your weight and calorie intake consistently, this TDEE will be adjusted to be more and more accurate."
    Do you have a bodyweight goal in mind?
      [Yes] -> Weight Goal Creation or [No, I just want to track.]
    Great! Finally, if you use MyFitnessPal, we can connect to your MyFitnessPal to sync your weight and calories everyday.
      Yes: -> Connect to MyFitnessPal, and sync last 2 years of data.
      No: -> No problem! You can always connect later in the Settings menu.
    
    Last but not least, make sure to log your weight and calories everyday for the most accurate results!

    Last but not least, do you want to turn on notification reminders to input weight and calories?
    Yes -> Great, add permissions.
    No -> No problem!
    

  */
  const onboardingPageOne = (
    <ScrollView style={styles.container}>
      <View style={styles.separator} />
      <View style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 35, fontWeight: 'bold', textAlign: 'center' }}>Welcome to Fitmoh!</Text>
        <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>Fitmoh is a tool to help you track your weight gain/loss cycles.</Text>
        <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>We need a few things from you first!</Text>
      </View>
      <Separator />
      <View style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Gender</Text>
        <View style={{ flexDirection: 'row', padding: 10, }}>
          <Pressable onPress={() => setGender("Male")} style={{ border: '1px solid blue', alignItems: 'center', padding: 10, backgroundColor: gender == "Male" ? 'gray' : null }}>
            <Text>Male</Text>
          </Pressable>
          <Pressable onPress={() => setGender("Female")} style={{ border: '1px solid blue', alignContent: 'center', padding: 10, backgroundColor: gender == "Female" ? 'gray' : null }}>
            <Text>Female</Text>
          </Pressable>
        </View>
      </View>
      <View style={{
        marginVertical: 10,
        height: 1,
        width: '100%',
      }} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <Pressable style={{ alignItems: 'center' }} onPress={() => setShowPicker(!showPicker)}><Text style={{ fontSize: 20, fontWeight: 'bold', paddingBottom: 10 }}>Activity Level</Text>
        <Text style={{ fontSize: 15, textAlign: 'center', paddingHorizontal: 5 }}>{getActivityLevel(activityLevel)}</Text>
        <FontAwesome
          name={showPicker ? "chevron-up" : "chevron-down"}
          size={18}
          color={Colors[colorScheme].text}
          style={{ margin: 5 }}
        /></Pressable>
      {showPicker && <Picker
        selectedValue={activityLevel} 
        itemStyle={{color: Colors[colorScheme].text}}
        onValueChange={(itemValue, itemIndex) =>
          setActivityLevel(itemValue)
        }>
        <Picker.Item label="Sedentary - Little to no exercise w/ desk job" value="1.2" />
        <Picker.Item label="Lightly Active - light cardio/sports 1-3 days/week" value="1.375" />
        <Picker.Item label="Moderately Active - moderate cardio/sports 6-7 days/week" value="1.55" />
        <Picker.Item label="Very Active - hard cardio/sports daily" value="1.725" />
        <Picker.Item label="Extremely Active - hard cardio/sports 2+ times/day" value="1.9" />
      </Picker>}
      <View style={{
        marginVertical: 10,
        height: 1,
        width: '100%',
      }} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <View style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Height</Text>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <StyledTextInput
              onChangeText={(value) => {
                if (value[value.length - 1] != '.' && value.length < 2) {
                  setHeightFeet(value)
                }
              }}
              keyboardType="numeric"
              returnKeyType="done"
              autoCapitalize="none"
              value={heightFeet}
              width={120}
              placeholder="Feet"
            /><Text>Feet</Text>
          </View>
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <StyledTextInput
              onChangeText={(value) => {
                if (value[value.length - 1] != '.' && value.length < 3 && (value >= "0" && value <= "12") || value == "") {
                  setHeightInches(value)
                }
              }}
              keyboardType="numeric"
              returnKeyType="done"
              autoCapitalize="none"
              value={heightInches}
              width={120}
              placeholder="Inches"
            />
            <Text>Inches</Text>
          </View>
        </View>
      </View>
      <Separator />
      <View style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>Enter your most current body weight</Text>
        <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>(you can guess!)</Text>
        <StyledTextInput
          onChangeText={(value) => setRecentWeight(value)}
          keyboardType="numeric"
          returnKeyType="done"
          autoCapitalize="none"
          value={recentWeight}
          placeholder="Weight (lbs)"
        />
      </View>
      <Separator />
      <Text style={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}>Birthday</Text>
      <TouchableOpacity style={{ padding: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }} onPress={() => setDatePickerVisibility(true)}>
        <Text style={{ paddingRight: 5, fontSize: 20, textAlign: 'center' }}>{moment(date).format("dddd, MMMM Do, YYYY")}</Text>
        <FontAwesome
          name="edit"
          size={18}
          color={Colors[colorScheme].text}
          style={{ margin: 5 }}
        />
      </TouchableOpacity>
      <DateTimePicker
        isVisible={isDatePickerVisible}
        onConfirm={handleDatePicked}
        onCancel={() => setDatePickerVisibility(false)}
        isDarkModeEnabled={colorScheme == 'dark'}
        date={date}
        maximumDate={new Date()}
      />
      <View style={{ alignItems: 'center', paddingBottom: 150 }}>
        <AwesomeButtonRick
          progress
          backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
          backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
          width={Dimensions.get('screen').width - 75}
          disabled={recentWeight == ""}
          onPress={() => setPageNum(2)}
        >
          <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>Next</Text>

        </AwesomeButtonRick>
      </View>
    </ScrollView>
  );

  const onboardingPageTwo = (
    <ScrollView style={styles.container}>
      <View style={styles.separator} />
      <View style={{ alignItems: 'center', paddingBottom: 10 }}>
      </View>
      <View style={{alignItems: 'center', paddingHorizontal: 10}}>
      <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>Estimated daily calories to maintain your weight (TDEE):</Text>
      <Text style={{ fontSize: 40, fontWeight: 'bold', textAlign: 'center' }}>{calculateTDEE(gender, Number(recentWeight), Number(heightFeet) * 12 + Number(heightInches), 20, Number(activityLevel))}</Text>
      <Text style={{ fontSize: 20, textAlign: 'center' }}>As you log your weight and calorie intake consistently, this TDEE will be adjusted to be more and more accurate.</Text>
      </View>
      <Separator />
      <View style={{alignItems: 'center'}}>
      <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', paddingHorizontal: 15 }}>Do you have a certain body weight goal in mind?</Text>
      <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', paddingBottom: 25, paddingHorizontal: 15 }}>(bulk, cut, maintain)</Text>

      <AwesomeButtonRick
        progress
        backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
        width={300}
        style={{marginBottom: 15}}
        onPress={() => setPageNum(3)}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>Yes</Text>
      </AwesomeButtonRick>
      <AwesomeButtonRick
        progress
        backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
        width={300}
        onPress={() => {
          addWeightMutation.mutate();
          setPageNum(5);
        }}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>No, I just want to track and see.</Text>
      </AwesomeButtonRick>
      </View>
    </ScrollView>
  )

  const onboardingPageThree = (
    <ScrollView style={styles.container}>
      <View style={styles.separator} />
      <View style={{alignItems: 'center'}}>
      <View style={{ flexDirection: 'row', padding: 20 }}>
        <Pressable onPress={() => { setGoal("Bulk"); setTargetChange("0.75") }} style={{ borderWidth: 1, borderColor: 'blue', borderStyle: 'solid', alignContent: 'center', padding: 10, backgroundColor: goal == "Bulk" ? 'gray' : null }}>
          <Text>Bulk</Text>
        </Pressable>
        <Pressable onPress={() => {setGoal("Maintain"); setTargetChange("0")}} style={{ borderWidth: 1, borderColor: 'blue', borderStyle: 'solid', alignItems: 'center', padding: 10, backgroundColor: goal == "Maintain" ? 'gray' : null }}>
          <Text>Maintain</Text>
        </Pressable>
        <Pressable onPress={() => { setGoal("Cut"); setTargetChange("-1") }} style={{ borderWidth: 1, borderColor: 'blue', borderStyle: 'solid', alignContent: 'center', padding: 10, backgroundColor: goal == "Cut" ? 'gray' : null }}>
          <Text>Cut</Text>
        </Pressable>
      </View>
      <Text>Target Weight Change Per Week (in lbs):</Text>
      <StyledTextInput
        onChangeText={(value) => setTargetChange(value)}
        keyboardType="numeric"
        returnKeyType="done"
        autoCapitalize="none"
        value={targetChange}
        placeholder="Target (lbs)"
      />
      <Text style={{ padding: 25, paddingBottom: 15, textAlign: "center" }}>
        To <Text style={{fontWeight: 'bold'}}>{targetChange > 0 ? "gain" : "lose"} {targetChange} lbs/week</Text>, you will need to eat around <Text style={{fontWeight: 'bold'}}>{Number(calculateTDEE(gender, Number(recentWeight), Number(heightFeet) * 12 + Number(heightInches), 20, Number(activityLevel))) + (500 * targetChange)} calories per day</Text>, based on a current TDEE of {calculateTDEE(gender, Number(recentWeight), Number(heightFeet) * 12 + Number(heightInches), 20, Number(activityLevel))}.
      </Text>
      <AwesomeButtonRick
        backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
        width={200}
        style={{marginBottom: 15}}
        onPress={() => {
          addWeightMutation.mutate();
          createGoalMutation.mutate();
          setPageNum(5);
        }}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>Create Goal</Text>
      </AwesomeButtonRick>
      <AwesomeButtonRick
        backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
        width={200}
        onPress={() => setPageNum(5)}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>Skip</Text>
      </AwesomeButtonRick>
      </View>
    </ScrollView>
  )

  const onboardingPageFour = (
    <View style={styles.container}>
      <View style={styles.separator} />
      <StyledTextInput
        onChangeText={(value) => setMfpUsername(value)}
        keyboardType="numeric"
        returnKeyType="done"
        autoCapitalize="none"
        value={mfpUsername}
        placeholder="MyFitnessPal Username"
      />
      <StyledTextInput
        onChangeText={(value) => setMfpPassword(value)}
        keyboardType="numeric"
        returnKeyType="done"
        autoCapitalize="none"
        secureTextEntry
        value={mfpPassword}
        placeholder="MyFitnessPal Password"
      />
      <AwesomeButtonRick
        progress
        backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
        width={200}
        onPress={() => setPageNum(5)}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>Connect</Text>

      </AwesomeButtonRick>
      <AwesomeButtonRick
        progress
        backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
        width={200}
        onPress={() => setPageNum(5)}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>Skip for Now</Text>

      </AwesomeButtonRick>
    </View>
  )

  const onboardingPageFive = (
    <View style={{flex: 1, alignItems: 'center'}}>
      <View style={styles.separator} />
      <Text style={{ fontWeight: 'bold', fontSize: 25, textAlign: 'center', paddingHorizontal: 10 }}>For the best results, consistency is important.</Text>
      <View style={styles.separator} />
      <Text style={{ fontWeight: 'bold', fontSize: 25, textAlign: 'center', paddingHorizontal: 10 }}>Try to log your weight and calories every single day!</Text>
      <View style={styles.separator} />
      <Text style={{ fontWeight: 'bold', fontSize: 25, textAlign: 'center', paddingHorizontal: 10, paddingBottom: 20 }}>The more data Fitmoh has, the more accurate it is.</Text>
      <AwesomeButtonRick
        backgroundColor={colorScheme == 'dark' ? "#30404D" : "#669EFF"}
        backgroundDarker={colorScheme == 'dark' ? "#202B33" : "#1F4B99"}
        width={200}
        onPress={() => {
          navigation.setOptions({ title: "Home", headerLeft: () => (
            <Pressable 
              onPress={() => navigation.navigate('Settings')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="gear"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginLeft: 15 }}
              />
            </Pressable>
          ),
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="plus"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginRight: 15 }}
              />
            </Pressable>
          ), });
          patchAccountMe.mutate();
          setPageNum(7);
        }}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 18, color: colorScheme == 'dark' ? 'white' : '#202B33' }}>Close</Text>

      </AwesomeButtonRick>
    </View>
  )
  
  if (account?.birthday == undefined && pageNum < 6) {
    navigation.setOptions({ title: "Onboarding" });
    if (pageNum == 1) {
      navigation.setOptions({ headerLeft: null, headerRight: null });
      return onboardingPageOne;
    } else if (pageNum == 2) {
      navigation.setOptions({ headerLeft: () => <FontAwesome
        name="arrow-left"
        size={20}
        color={Colors[colorScheme].text}
        onPress={() => setPageNum(1)}
        style={{ margin: 10, marginTop: 10 }}
    /> });
      return onboardingPageTwo;
    } else if (pageNum == 3) {
      navigation.setOptions({ headerLeft: () => <FontAwesome
        name="arrow-left"
        size={20}
        color={Colors[colorScheme].text}
        onPress={() => setPageNum(2)}
        style={{ margin: 10, marginTop: 10 }}
    /> });
      return onboardingPageThree;
    } else if (pageNum == 4) {
      return onboardingPageFour;
    } else if (pageNum == 5) {
      navigation.setOptions({ headerLeft: null, headerRight: null });
      return onboardingPageFive;
    }
  } else {

  }
  return (
    <View style={styles.container}>
      {/* <Text>Token {JSON.stringify(token)}</Text> */}
      {/* <Text style={styles.title}>You don't have enough entries yet to show useful data! Start adding your bodyweight daily using the + in the top-right corner.</Text> */}
      <View style={styles.separator} />
      <View style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Text style={{ fontSize: 18, color: titleColor, fontWeight: 'bold' }}>Current Avg. Weight</Text>
        <Text style={{ fontSize: 35, fontWeight: 'bold' }}>{account?.average_weight?.toFixed(1) || 'None'} lbs</Text>
        <View style={{ flexDirection: 'row' }}>
          {/* <Text>(Difference from last week:</Text> */}
          <Text style={{ color: account?.week_difference < 0 ? '#F55656' : '#15B371', fontWeight: 'bold' }}>(Difference from last week: {account?.week_difference >= 0 ? "+" : null}{account?.week_difference?.toFixed(2) || 'N/A'})</Text>
        </View>
      </View>
      <View style={{
        marginVertical: 10,
        height: 1,
        width: '100%',
      }} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <View style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Pressable onPress={() => Alert.alert('Sources', medicalSourcesText)}><Text style={{ fontSize: 18, color: titleColor, fontWeight: 'bold' }}>Your TDEE (?)</Text></Pressable>
        <Text style={{ fontSize: 25, fontWeight: 'bold' }}>{accountIsLoading || info.isLoading ? "Calculating..." : account?.tdee || getTDEE() }</Text>
      </View>
      <View style={{alignItems: 'center'}}>
      {activeGoal && (
        <View style={{ flexDirection: 'row', borderWidth: 0, borderRadius: 20, marginVertical: 5, paddingHorizontal: 10, borderColor: '#5C7080' }}>
          <Text style={{ fontSize: 15, marginTop: 10, marginRight: 5, opacity: 0.33 }}>{latestActiveWeightGoalData.targetChangePerWeek >= 0 ? "+" : null}{latestActiveWeightGoalData.targetChangePerWeek} lbs/week</Text>
          <Text style={{ fontSize: 35, fontWeight: 'bold' }}>&#8595;</Text>
          <Text style={{ fontSize: 15, marginTop: 10, marginLeft: 5, opacity: 0.33 }}>{latestActiveWeightGoalData.targetChangePerWeek >= 0 ? "+" : null}{(latestActiveWeightGoalData.targetChangePerWeek * 500).toFixed()} calories</Text>
        </View>
      )}
      </View>
      {activeGoal && (
        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Text style={{ fontSize: 25, color: titleColor, fontWeight: 'bold' }}>Target Calories</Text>
          <Text style={{ fontSize: 45, fontWeight: 'bold' }}>{targetCals}</Text>
        </View>
      )}
      <View style={{
        marginVertical: 10,
        height: 1,
        width: '100%',
      }} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      {latestActiveWeightGoalQuery.isLoading && <ActivityIndicator size="large" />}
      {activeGoal ? (
        <TouchableOpacity style={{ flexDirection: 'row', marginBottom: 15, alignItems: 'center' }} onPress={() => navigation.navigate('BlockScreen', latestActiveWeightGoalData as any)}>
          <View style={{ flexDirection: 'column', alignItems: 'center', flex: 9 }}>
            <Text style={{ fontSize: 18, color: titleColor, fontWeight: 'bold' }}>Current Goal</Text>
            <Text style={styles.title}>{latestActiveWeightGoalData.title}</Text>
          </View>
          {Platform.OS != 'web' && <FontAwesome
            name="chevron-right"
            size={20}
            color={Colors[colorScheme].text}
            style={{ marginLeft: -42, flex: 1 }} />}

        </TouchableOpacity>
      ) : null}
      {activeGoal ? <WeightChart data={latestActiveWeightGoalData.weight_entries} /> : null}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  title: {
    fontSize: 23,
    fontWeight: 'bold',
  },
  separator: {
    marginTop: 15,
    height: 1,
    width: '80%',
  },
});
